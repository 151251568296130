<template>
  <div class="menu-container fixed">
    <div id="hamburger-menu"
      :class="{'open': isOpen}"
      @click="emitIsOpen(!isOpen)"
    >
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
    <div class="side-content" :class="{'content-open': isOpen}" @click="emitChangeRoute()">
      <router-link class="home-link" to="/">
        <site-title />
      </router-link>
      <span class="links">
        <div v-for="link in LINKS" :key="link.title">
          <router-link v-if="link.inMenu" :to="link.link">{{ link.title }}</router-link>
        </div>
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';

import LINKS from '@/config/links';
import SiteTitle from '@/components/SiteTitle.vue';

export default Vue.extend({
  name: 'HamburgerMenu',
  components: {
    SiteTitle
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      LINKS,
    };
  },
  methods: {
    emitChangeRoute() {
      this.$emit('routeChanged', true);
    },
    emitIsOpen(isOpen) {
      this.$emit('isOpenChanged', isOpen);
    },
  },
});
</script>

<style lang="scss" scoped>
.content-open {
  width: 250px !important;
}

.home-link {
  color: white !important;
  display: block;
  padding-left: 8px;
  text-decoration: none;
}

.fixed {
  display: block !important;
  position: fixed;
  top: 0px;
}

.links {
  display: block;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 30px;
  margin-bottom: auto;
  margin-left: 20px;
  padding-top: 12px;
}

.links a {
  color: white;
  font-weight: bold;
  text-decoration: none;
}

.links a.router-link-exact-active {
  color: white;
}

.menu-container {
  display: none;
  z-index: 1000;
}

.side-content {
  background-image: linear-gradient(110deg, black, white);
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
  display: block !important;
  height: 100%;
  left: 0;
  overflow-x: hidden;
  padding-top: 44px;
  position: fixed;
  text-align: left;
  top: 0px;
  transition: 0.2s;
  width: 0;
  z-index: 1001;
}

#hamburger-menu {
  width: 32px;
  height: 48px;
  position: relative;
  margin: 8px 8px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  z-index: 1002;
}

#hamburger-menu {
  
}

#hamburger-menu span {
  display: block;
  position: absolute;
  height: 4px;
  width: 50%;
  background: white;
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#hamburger-menu span:nth-child(even) {
  left: 50%;
  border-radius: 0 9px 9px 0;
}

#hamburger-menu span:nth-child(odd) {
  left:0px;
  border-radius: 9px 0 0 9px;
}

#hamburger-menu span:nth-child(1), #hamburger-menu span:nth-child(2) {
  top: 0px;
}

#hamburger-menu span:nth-child(3), #hamburger-menu span:nth-child(4) {
  top: 10px;
}

#hamburger-menu span:nth-child(5), #hamburger-menu span:nth-child(6) {
  top: 20px;
}

#hamburger-menu.open span:nth-child(1),#hamburger-menu.open span:nth-child(6) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#hamburger-menu.open span:nth-child(2),#hamburger-menu.open span:nth-child(5) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#hamburger-menu.open span:nth-child(1) {
  left: 2px;
  top: 5px;
}

#hamburger-menu.open span:nth-child(2) {
  left: calc(50% - 2px);
  top: 5px;
}

#hamburger-menu.open span:nth-child(3) {
  left: -50%;
  opacity: 0;
}

#hamburger-menu.open span:nth-child(4) {
  left: 100%;
  opacity: 0;
}

#hamburger-menu.open span:nth-child(5) {
  left: 2px;
  top: 17px;
}

#hamburger-menu.open span:nth-child(6) {
  left: calc(50% - 2px);
  top: 17px;
}
</style>
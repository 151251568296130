const ExpenseData = {
  getters: {
    
  },
  actions: {
    addNewExpense(context, expense) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverAddNewExpense', expense)
            .then((newExpense) => resolve(newExpense));
        } catch {
          reject(null);
        }
      });
    },
    deleteExpense(context, id) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverDeleteExpense', id)
            .then((isSuccess) => resolve(isSuccess));
        } catch {
          reject(false);
        }
      });
    },
    getExpense(context, id) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverGetExpense', id)
            .then((expense) => resolve(expense));
        } catch {
          reject(null);
        }
      });
    },
    getExpenses(context, dateQuery) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverGetExpenses', dateQuery)
            .then((expenses) => resolve(expenses));
        } catch {
          reject([]);
        }
      });
    },
    updateExpense(context, updatedExpense) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverUpdateExpense', updatedExpense)
            .then((expense) => resolve(expense));
        } catch {
          reject(null);
        }
      });
    },
  },
  mutations: {

  },
  state: {
    
  },
};

export default ExpenseData;

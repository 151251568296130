<template>
    <img :src="LOGO" @click="homeLink()" />
</template>

<script lang="ts">
import Vue from 'vue';

import logo from '../../assets/bare_nails_logo.png';

export default Vue.extend({
   name: 'FullTitle',
   data() {
       return {
           LOGO: logo,
       };
   },
   methods: {
       homeLink() {
           // Make sure we don't link to where we currently are located...
           if (this.$route.name === 'Home') return;
           this.$emit('routeChanged', true);
           this.$router.push('/');
       },
   },
});
</script>

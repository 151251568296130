<template>
  <div>
    <b-table
      :loading="isLoading"
      :data="data"
      :striped="true"
      :narrowed="true"
      :mobile-cards="true">
      <b-table-column label="Title" subheading="Total" v-slot="props">
        {{ props.row.title }}
      </b-table-column>
      <b-table-column label="Tips" :subheading="tipsTotal | toCurrency" v-slot="props">
        {{ props.row.tip | toCurrency }}
      </b-table-column>
      <b-table-column label="Cost" :subheading="costTotal | toCurrency" v-slot="props">
        <b-tag v-if="props.row.isProfit" type="is-success is-light">{{ props.row.cost | toCurrency }}</b-tag>
        <b-tag v-else type="is-danger is-light">{{ props.row.cost | toCurrency }}</b-tag>
      </b-table-column>
    </b-table>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'RollupTable',
  props: {
    data: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    costTotal() {
      return this.data.length === 0 ? 0 : this.data.reduce((total, rollup) => {
        if (rollup.isProfit) return total + rollup.cost;
        return total - rollup.cost;
      }, 0);
    },
    tipsTotal() {
      return this.data.length === 0 ? 0 : this.data.reduce((total, rollup) => {
        if (rollup.tip) return total + rollup.tip;
        return total;
      }, 0);
    },
  },
});
</script>

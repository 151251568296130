<template>
  <b-modal
    v-model="isActive"
    has-modal-card
    trap-focus
    :destroy-on-hide="false"
    aria-role="dialog"
    aria-label="GiftCard"
    aria-modal
    @close="close"
  >
    <template>
      <b-loading v-model="isLoading" />
      <form action="">
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">{{ title }}</p>
            <button type="button" class="delete" @click="close" />
          </header>
          <section class="modal-card-body">
            <div class="columns column is-full">
              <div class="column is-half">
                <b-field class="label"
                  label="Card ID"
                  :type="{'is-danger': !cardIdValid}"
                  :message="{'Need a card ID.': !cardIdValid}">
                  <b-input
                    :readonly="giftCardId != null && giftCardId !== ''"
                    maxlength="30"
                    v-model="model.cardId"
                    required />
                </b-field>
              </div>
              <div class="column is-half">
                <b-field class="label"
                  label="Tips"
                  :type="{'is-danger': !tipsValid}"
                  :message="{'Set tips to at least 0.': !tipsValid}">
                  <b-numberinput v-model="model.tips" :min="0" expanded required />
                </b-field>
              </div>
            </div>
            <div v-if="!giftCardId" class="columns column is-full">
              <div class="column is-half">
                <b-field class="label"
                  label="Initial Amount"
                  :type="{'is-danger': !eitherOrValid}"
                  :message="{'Select Amount OR Services': !eitherOrValid}">
                  <b-numberinput v-model="amount" :min="0" expanded />
                </b-field>
              </div>
              <div class="column is-half">
                <b-field class="label"
                  label="Initial Services"
                  :type="{'is-danger': !eitherOrValid}"
                  :message="{'Select Services OR Amount': !eitherOrValid}">
                  <b-select native-size="2" v-model="service" expanded multiple>
                    <option v-for="service in services" :key="service.id" :value="service.id">{{ service.service }}</option>
                  </b-select>
                </b-field>
              </div>
            </div>
            <div v-else class="columns column is-full">
              <div class="column is-half">
                <b-field class="label"
                  label="Remaining Amount">
                  <b-numberinput v-model="amount" :min="0" expanded />
                </b-field>
              </div>
              <div class="column is-half">
                <b-field class="label"
                  label="Remaining Services">
                  <b-select native-size="2" v-model="service" expanded multiple>
                    <option v-for="service in services" :key="service.id" :value="service.id">{{ service.service }}</option>
                  </b-select>
                </b-field>
              </div>
            </div>
          </section>
          <footer class="modal-card-foot">
            <b-button v-if="!giftCardId" class="add-button" @click="addNewGiftCard">Add</b-button>
            <b-button v-else class="add-button" @click="updateGiftCard">Update</b-button>
          </footer>
        </div>
      </form>
    </template>
  </b-modal>
</template>

<script lang="ts">
import Vue from 'vue';

import { failure, success } from '@/utility/messages';
import BASIC_SERVICES from '@/config/basicServices';
import { checkValue } from '@/utility/index';
import { modelData, tips, isValid } from '@/mixins/checkers';

export default Vue.extend({
  name: 'GiftCardData',
  mixins: [
    modelData,
  ],
  props: {
    giftCardId: {
      type: String,
      required: false,
    },
    isModalActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      amount: null,
      isActive: false,
      isLoading: false,
      service: [],
    };
  },
  computed: {
    cardIdValid() {
      return checkValue({value: this.model.cardId, type: 'string'});
    },
    eitherOrValid() {
      return this.amount > 0 || this.service.length > 0;
    },
    services() {
      return BASIC_SERVICES;
    },
    title() {
      return this.clientId ? 'Edit Gift Card' : 'Add New Gift Card';
    },
    ...tips,
  },
  created() {
    if (this.giftCardId) {
      this.getGiftCard(this.giftCardId);
    }
  },
  watch: {
    amount(val) {
      if (val) this.service = [];
    },
    giftCardId(newVal) {
      this.getGiftCard(newVal);
    },
    isModalActive(newVal) {
      this.isActive = newVal;
    },
    service(val) {
      if (val.length > 0) this.amount = 0;
    },
  },
  methods: {
    addNewGiftCard() {
      if (!isValid(this.cardIdValid, this.eitherOrValid, this.tipsValid)) return;

      this.isLoading = true;

      const model = {
        ...this.model,
        initialTotal: this.amount,
        service: this.service,
      };

      this.$store.dispatch('addNewGiftCard', model)
        .then((card) => {
          if (card) {
            this.$store.dispatch('hotReload');
            success(this, {message: 'Created gift card.'});
            this.close();
          } else {
            failure(this, {message: 'Error creating the gift card.'});
          }
        })
        .catch(() => failure(this, {message: 'Error creating the gift card.'}))
        .finally(() => this.isLoading = false);
    },
    close() {
      this.model = {};
      this.$emit('close', true);
    },
    getGiftCard(id) {
      this.isLoading = true;

      this.$store.dispatch('getGiftCard', id)
        .then((card) => {
          if (card) {
            this.model = {...card, tips: card.tip};
            this.amount = this.model.remaining;
            this.service = this.model.remainingServices;
          } else {
            failure(this, {message: 'Error retrieving gift card.'});
          }
        })
        .catch(() => failure(this, {message: 'Error retrieving gift card.'}))
        .finally(() => this.isLoading = false);
    },
    updateGiftCard() {
      this.isLoading = true;

      const updatedCard = {
        id: this.model.id,
        initialTotal: this.model.initialTotal,
        tip: this.model.tip,
        remaining: this.amount,
        service: this.model.service,
        remainingServices: this.service,
        year: this.model.year,
        day: this.model.day,
        month: this.model.month,
        cardId: this.model.cardId,
        createdOn: this.model.createdOn,
        editedOn: new Date().getTime(),
      };

      this.$store.dispatch('updateGiftCard', updatedCard)
        .then((card) => {
          if (card) {
            this.$store.dispatch('hotReload');
            success(this, {message: 'Updated the gift card.'});
            this.close();
          } else {
            failure(this, {message: 'Error updating the gift card.'});
          }
        })
        .catch(() => failure(this, {message: 'Error updating the gift card.'}))
        .finally(() => this.isLoading = false);
    },
  },
});
</script>

<style lang="scss" scoped>
.add-button {
  float: right;
}

.gift-card-header {
  font-size: 24px;
}

.label {
  text-align: left;
}
</style>

<template>
  <div v-if="isLoggedIn" class="parent bn-container bn-card bn-site-background-color bn-site-color">
    <b-loading v-model="isLoading" />
    <div class="bn-column">
      <div class="id-title">{{ id }} - {{ appointment.status }}</div>
      <div class="columns column is-full">
        <div class="column is-4">
          <b-field class="label"
            label="First Name"
            :type="{'is-danger': !firstNameValid}"
            :message="{'Need a first name.': !firstNameValid}">
            <b-input
              maxlength="30"
              v-model="newAppointment.firstName"
              required />
          </b-field>
        </div>
        <div class="column is-4">
          <b-field class="label"
            label="Last Name"
            :type="{'is-danger': !lastNameValid}"
            :message="{'Need a last name.': !lastNameValid}">
            <b-input
              maxlength="30"
              v-model="newAppointment.lastName"
              required />
          </b-field>
        </div>
        <div class="column is-4">
          <b-field class="label"
            label="Phone Number"
            :type="{'is-danger': !phoneNumberValid}"
            :message="{'Need a valid number (123-456-7890).': !phoneNumberValid}">
            <b-input
              maxlength="30"
              placeholder="123-456-7890"
              v-model="newAppointment.phoneNumber"
              required />
          </b-field>
        </div>
      </div>
      <div class="columns column is-full">
        <div class="column is-4">
          <b-field
            class="label"
            label="Requested Date/Time"
            :type="{'is-danger': !dateValid}"
            :message="{'Please enter a date and time.': !dateValid}">
            <b-datetimepicker
              class="date-time-picker"
              icon="calendar-today"
              placeholder="Click to select..."
              position="is-top-right"
              v-model="newAppointment.requestedFor" />
          </b-field>
        </div>
        <div class="column is-4">
          <b-field
            class="label"
            label="Ordered On">
            <b-datetimepicker
              class="date-time-picker"
              icon="calendar-today"
              position="is-top-right"
              v-model="newAppointment.orderedOn"
              readonly />
          </b-field>
        </div>
        <div class="column is-4">
          <b-field
            class="label select-container"
            label="Status"
            :type="{'is-danger': !statusValid}"
            :message="{'Please select a status.': !statusValid}">
            <b-select v-model="newAppointment.status" required>
              <option v-for="status in AppointmentStatus"
                :key="status"
                :value="status">
                {{ status }}
              </option>
            </b-select>
          </b-field>
        </div>
      </div>
      <div class="columns column is-full">
        <div class="column is-4">
          <b-field
            class="label select-container"
            label="Candle"
            :type="{'is-danger': !candleValid}"
            :message="{'Please select a candle.': !candleValid}">
            <b-select v-model="newAppointment.candle" required>
              <option v-for="candle in CANDLES" :key="candle" :value="candle">{{ candle }}</option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-4">
          <b-field class="label"
            label="Music">
            <b-input
              maxlength="30"
              placeholder="Spa Music"
              v-model="newAppointment.music" />
          </b-field>
        </div>
        <div class="column is-4">&nbsp;</div>
      </div>
      <div class="columns column is-full">
        <div class="column is-4">
          <b-field
            class="label select-parent-container"
            label="Services"
            :type="{'is-danger': !servicesValid}"
            :message="{'Please select at least one service.': !servicesValid}">
            <b-select class="select-size" v-model="newAppointment.services" multiple required>
              <option v-for="service in BASIC_SERVICES"
                :key="service.id"
                :value="service.id">
                {{ service.service }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-8">
          <b-field class="label" label="Comments">
            <b-input
              type="textarea"
              class="text-area"
              placeholder="Enter any additional comments..."
              maxlength="600"
              v-model="newAppointment.comments" />
          </b-field>
        </div>
      </div>
      <div class="columns column is-full">
        <div class="column is-8">&nbsp;</div>
        <div class="column is-4">
          <b-button type="is-danger" @click="deleteAppointment">DELETE</b-button>
          <b-button type="is-light" class="reset-button" @click="reset" inverted>Reset</b-button>
          <b-button type="is-dark" @click="update" inverted>Update</b-button>
        </div>
      </div>
    </div>
    <b-button class="admin-button" @click="$router.push('/admin')">
      <i class="fa fa-arrow-left"></i> ADMIN
    </b-button>
  </div>
  <div v-else>
    <br><br><br><br><br><br><br><br><br><br><br><br>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';

import { AppointmentStatus } from '@/models/index';

import { failure, success } from '@/utility/messages';
import ADMIN_ACCESS from '@/mixins/adminAccess';
import BASIC_SERVICES from '@/config/basicServices';
import CANDLES from '@/config/candles';
import TOP_SCROLL from '@/mixins/topScroll';

export default Vue.extend({
  name: 'AppointmentEdit',
  mixins: [
    ADMIN_ACCESS,
    TOP_SCROLL,
  ],
  data() {
    return {
      appointment: {},
      AppointmentStatus,
      BASIC_SERVICES,
      CANDLES,
      isLoading: false,
      newAppointment: {},
    };
  },
  computed: {
    candleValid() {
      return this.checkValue(this.newAppointment.candle, 'string');
    },
    dateValid() {
      return this.checkValue(this.newAppointment.requestedFor, 'date');
    },
    firstNameValid() {
      return this.checkValue(this.newAppointment.firstName, 'string');
    },
    id() {
      return this.$route.params.id;
    },
    lastNameValid() {
      return this.checkValue(this.newAppointment.lastName, 'string');
    },
    phoneNumberValid() {
      return this.checkValue(this.newAppointment.phoneNumber, 'phone');
    },
    servicesValid() {
      return this.checkValue(this.newAppointment.services, 'array');
    },
    statusValid() {
      return this.checkValue(this.newAppointment.status, 'string');
    },
  },
  created() {
    this.getAppointment();
  },
  methods: {
    checkValue(value, type) {
      if (!value || !type) return false;
      
      const regex = /[0-9]{3}-{1}[0-9]{3}-{1}[0-9]{4}/g;

      switch (type) {
        case 'array':
          return value && value instanceof Array && value.length > 0;
        case 'date':
          return value && !isNaN(Number(value));
        case 'phone':
          return value && regex.exec(value);
        case 'string':
          return value && value.length > 0;
        default:
          return false;
      }
    },
    deleteAppointment() {
      // Make sure we really want to do this....
      this.$buefy.snackbar.open({
        actionText: 'DELETE',
        cancelText: 'Cancel',
        duration: 10000,
        position: 'is-bottom-left',
        type: 'is-danger',
        message: `Are you sure you want to permanently delete?
          You can always change the status to CANCELLED...`,
        onAction: () => {
          this.isLoading = true;

          this.$store.dispatch('deleteAppointment', this.id)
            .then((isSuccess) => {
              if (isSuccess) {
                success(this, {message: 'Appointment deleted.'});
                this.$router.push('/admin');
              } else {
                failure(this, {message: 'Error deleting the appointment.'});
              }
            })
            .catch(() => failure(this, {message: 'Error deleting the appointment.'}))
            .finally(() => this.isLoading = false);
        },
      });
    },
    getAppointment() {
      this.isLoading = true;

      this.$store.dispatch('getAppointment', this.id)
        .then((appt) => {
          if (appt) {
            this.appointment = appt;
            this.newAppointment = {
              ...this.appointment,
              orderedOn: this.setDateFormat(this.appointment.orderedOn),
              requestedFor: this.setDateFormat(this.appointment.requestedFor),
            };
          } else {
            failure(this, {message: 'Error fetching the record.'});
          }
        })
        .catch(() => failure(this, {message: 'Error fetching the record.'}))
        .finally(() => this.isLoading = false);
    },
    reset() {
      this.newAppointment = {
        ...this.appointment,
        requestedFor: this.setDateFormat(this.appointment.requestedFor),
        orderedOn: this.setDateFormat(this.appointment.orderedOn),
      };
    },
    setDateFormat(ms) {
      return ms && !isNaN(ms) ? new Date(Number(ms)) : new Date();
    },
    update() {
      let valid = this.candleValid;
      valid = this.dateValid && valid;
      valid = this.firstNameValid && valid;
      valid = this.lastNameValid && valid;
      valid = this.servicesValid && valid;
      valid = this.phoneNumberValid && valid;

      if (!valid) return;

      this.isLoading = true;

      const comments = this.newAppointment.comments ? this.newAppointment.comments : 'None';
      const music = this.newAppointment.music ? this.newAppointment.music : 'Spa Music';
      const updatedAppointment = {
        id: this.newAppointment.id,
        firstName: this.newAppointment.firstName,
        lastName: this.newAppointment.lastName,
        phoneNumber: this.newAppointment.phoneNumber,
        candle: this.newAppointment.candle,
        music: music,
        services: this.newAppointment.services,
        comments: comments,
        requestedFor: this.newAppointment.requestedFor.getTime(),
        editedOn: new Date().getTime(),
        status: this.newAppointment.status,
      };

      this.$store.dispatch('updateAppointment', updatedAppointment)
        .then((appt) => {
          if (appt) {
            success(this, {message: 'Appointment updated.'});
            this.$router.push('/admin');
          } else {
            failure(this, {message: 'Error updating record.'});
          }
        })
        .catch(() => failure(this, {message: 'Error updating record.'}))
        .finally(() => this.isLoading = false);
    },
  },
});
</script>

<style lang="scss" scoped>
.admin-button {
  float: left;
  margin-left: 12px;
}

.id-title {
  padding-bottom: 16px;
}

.label {  
  text-align: left;
}

.label::v-deep label {
  color: white;
}

.parent {
  padding-top: 30px;
}

.reset-button {
  background-color: rgba(255, 255, 255, 0.5) !important;
  margin-left: 12px;
  margin-right: 12px;
}

.select-container::v-deep span, .select-container::v-deep select {
  width: 100%;
}

.select-size::v-deep span, .select-size::v-deep select {
  width: 100%;
}

.text-area::v-deep textarea {
  height: 154px;
}
</style>

<template>
  <div>
    &nbsp;
  </div>
</template>

<script lang="ts">
import Vue from 'vue';

import { Auth, DataStore } from 'aws-amplify';

export default Vue.extend({
  name: 'Logout',
  created() {
    DataStore.clear();
    Auth.signOut();
  },
});
</script>

<template>
    <div class="site-title">
        B.A.R.E. Nails
    </div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
    name: 'SiteTitle',
});
</script>

<style scoped>
.site-title {
    font-family: 'Luxury Royale JF';
    font-size: 1.5em;
}
</style>

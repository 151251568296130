<template>
  <div id="app" class="bn-container">
    <menu-bar v-if="isLargeMode" />
    <hamburger-menu v-else :isOpen="isOpen" @isOpenChanged="isOpenChanged" @routeChanged="routeChanged" />
    <full-title class="header bn-column bn-title" :class="{'bn-sticky': !isLargeMode}" @routeChanged="routeChanged" />
    <login-form />
    <modal />
    <router-view/>
    <div class="bn-column bn-card bn-site-background-color bn-site-color contact-parent-container">
      <!-- <div class="contact-info">
        <div class="italic">Serving the Southeast Aurora, CO area</div>
        <div>(303) 489-2709</div>
        <div>4912 S. Odessa Ct. Aurora, CO 80015</div>
      </div> -->
      <social-media-group class="links" />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters } from 'vuex';

import Buefy from 'buefy';
import 'buefy/dist/buefy.css';

import Amplify, { Hub } from 'aws-amplify';
import awsconfig from '@/aws-exports';

import FullTitle from '@/components/FullTitle.vue';
import HamburgerMenu from '@/components/HamburgerMenu.vue';
import LoginForm from '@/components/subcomponent/LoginForm.vue';
import MenuBar from '@/components/MenuBar.vue';
import Modal from '@/components/subcomponent/Modal.vue';
import SocialMediaGroup from '@/components/subcomponent/SocialMediaGroup.vue';

Vue.use(Buefy);

export default Vue.extend({
  name: 'App',
  components: {
    FullTitle,
    HamburgerMenu,
    LoginForm,
    MenuBar,
    Modal,
    SocialMediaGroup,
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    ...mapGetters([
      'getError',
      'isLargeMode',
    ]),
  },
  watch: {
    getError(val) {
      this.$buefy.snackbar.open({
        duration: 30000,
        type: 'is-danger',
        message: `${val}`,
      });
    },
  },
  created() {
    // Amplify backend setup
    Amplify.configure(awsconfig);
    Hub.listen('auth', this.hubListener);

    this.$store.dispatch('setLargeMode', window.innerWidth > 800);

    window.addEventListener('resize', () => {
      this.$store.dispatch('setLargeMode', window.innerWidth > 800);
    });
  },
  methods: {
    hubListener(data) {
      switch (data.payload.event) {
        case 'signIn':
          this.$router.go();
          break;
        case 'signUp':
          break;
        case 'signOut':
          this.$router.push('/');
          break;
        case 'signIn_failure':
          this.$router.push('/');
          break;
        case 'tokenRefresh':
          break;
        case 'tokenRefresh_failure':
          break;
        case 'configured':
          break;
        default:
          break;
      }
    },
    isOpenChanged(val) {
      this.isOpen = val;
    },
    routeChanged() {
      this.isOpen = false;
    },
  },
});
</script>

<style>
#app {
  font-family: Arial, Helvetica, sans-serif;
  margin-top: -8px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

.contact-info {
  font-size: 16px;
  padding-bottom: 16px;
  padding-top: 20px;
  z-index: 10;
}

.contact-parent-container {
  z-index: 10;
}

.italic {
  font-style: italic;
  padding-bottom: 8px;
}

.links {
  padding-top: 4px;
  z-index: 12;
  --width-mobile: 12;
}
</style>

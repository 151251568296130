<template>
  <div v-if="isLoggedIn" class="bn-container bn-site-color bn-site-background-color">
    <div class="bn-column bn-card bn-site-background-color" :class="{'popup': isModalActive}">
      <div class="bn-column bn-luxury admin-title">Gift Cards</div>
      <div class="bn-container content-container">
        <b-table
          class="giftcards-table"
          :loading="isLoading"
          :data="giftCards"
          :striped="true"
          :narrowed="true"
          :hoverable="true"
          :selected.sync="selected"
          :mobile-cards="true">
          <b-table-column label="ID" v-slot="props">
            {{ props.row.cardId }}
          </b-table-column>
          <b-table-column label="Initial" v-slot="props">
            {{ props.row.service.join(", ") || props.row.initialTotal }}
          </b-table-column>
          <b-table-column label="Remaining" v-slot="props">
            {{ props.row.service.length > 0 ? props.row.remainingServices.join(", ") : props.row.remaining }}
          </b-table-column>
          <b-table-column label="Tip" v-slot="props">
            {{ props.row.tip || '' }}
          </b-table-column>
        </b-table>
        <b-button class="add-button" @click="addNewGiftCard">Add</b-button>
      </div>
    </div>
    <b-button class="admin-button" @click="$router.push('/admin')">
      <i class="fa fa-arrow-left"></i> ADMIN
    </b-button>
    <gift-card-data :isModalActive="isModalActive" :giftCardId="giftCardId" @close="isModalActive = false" />
  </div>
  <div v-else>
    <br><br><br><br><br><br><br><br><br><br><br><br>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';

import { failure } from '@/utility/messages';
import ADMIN_ACCESS from '@/mixins/adminAccess';
import GiftCardData from '@/components/subcomponent/GiftCardData';
import HOT_RELOAD from '@/mixins/hotReload';

export default Vue.extend({
  name: 'GiftCards',
  mixins: [
    ADMIN_ACCESS,
    HOT_RELOAD,
  ],
  components: {
    GiftCardData,
  },
  data() {
    return {
      giftCardId: '',
      giftCards: [],
      isLoading: false,
      isModalActive: false,
      selected: null,
    };
  },
  created() {
    this.getData();
  },
  watch: {
    isModalActive(newVal) {
      this.$store.dispatch('modalOpen', newVal);
    },
    selected(val) {
      this.editGiftCard(val.id);
    },
  },
  methods: {
    addNewGiftCard() {
      this.giftCardId = '';
      this.isModalActive = true;
    },
    editGiftCard(id) {
      this.giftCardId = id;
      this.isModalActive = true;
    },
    getData() {
      this.isLoading = true;

      this.$store.dispatch('getAllGiftCards')
        .then((cards) => this.giftCards = cards)
        .catch(() => failure(this, {message: 'Error retrieving the gift cards.'}))
        .finally(() => this.isLoading = false);
    },
  },
});
</script>

<style lang="scss" scoped>
.add-button {
  margin-left: auto;
  margin-top: 8px;
}

.admin-button {
  float: left;
  margin-left: 12px;
}

.admin-title {
  font-size: 60px;
  padding-bottom: 28px;
  padding-top: 28px;
}

.content-container {
  padding: 0px 12px;
  position: relative;
}

.giftcards-table {
  cursor: pointer;
  padding-bottom: 24px;
  text-align: left;
  width: 100%;
}

.popup {
  z-index: 0 !important;
}
</style>

<template>
  <div 
    v-if="isLoginModalOpen"
    class="modal"
  >
    <div class="modal-content">
      <amplify-authenticator>
        <div>
          B.A.R.E. Nails
        </div>
      </amplify-authenticator>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters } from 'vuex';

import '@aws-amplify/ui-vue';

export default Vue.extend({
  name: 'LoginForm',
  computed: {
    ...mapGetters([
      'isLoginModalOpen',
    ]),
  },
});
</script>

<style lang="scss" scoped>
@-webkit-keyframes animatetop {
  from {top:-300px; opacity:0} 
  to {top:0; opacity:1}
}

@keyframes animatetop {
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
}

.modal {
  background-color: rgba(0,0,0,0.4);
  display: block;
  height: 100%;
  left: 0;
  overflow: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2000;
}

.modal-content {
  animation-duration: 0.4s;
  animation-name: animatetop;
  margin: auto;
  max-width: 80%;
  position: relative;
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
}
</style>

// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const AppointmentStatus = {
  "REQUEST": "REQUEST",
  "CONFIRMED": "CONFIRMED",
  "COMPLETED": "COMPLETED",
  "CANCELLED": "CANCELLED",
  "REJECTED": "REJECTED"
};

const { Payment, GiftCard, Expense, Profit, Client, Appointment } = initSchema(schema);

export {
  Payment,
  GiftCard,
  Expense,
  Profit,
  Client,
  Appointment,
  AppointmentStatus
};
import PICTURE_SETUP from '@/enums/PictureSetup';

const PHOTO_GROUPS = {
  ombre: [
    {
      images: [
        require('../../assets/photos/ombre/thumbnails/blue_butterfly.jpg'),
        require('../../assets/photos/ombre/thumbnails/asian.jpg'),
        require('../../assets/photos/ombre/thumbnails/black.jpg'),
      ],
      setup: PICTURE_SETUP.ONE_TOP_TWO_BOTTOM,
    },
    {
      images: [
        require('../../assets/photos/ombre/thumbnails/blue_glitter.jpg'),
        require('../../assets/photos/ombre/thumbnails/blue.jpg'),
        require('../../assets/photos/ombre/thumbnails/cirque.jpg'),
      ],
      setup: PICTURE_SETUP.TWO_TOP_ONE_BOTTOM,
    },
    {
      images: [
        require('../../assets/photos/ombre/thumbnails/purple_tip.jpg'),
      ],
      setup: PICTURE_SETUP.DEFAULT,
    },
    {
      images: [
        require('../../assets/photos/ombre/thumbnails/glitter_tip.jpg'),
        require('../../assets/photos/ombre/thumbnails/purple_butterfly.jpg'),
        require('../../assets/photos/ombre/thumbnails/silver_tip.jpg'),
        require('../../assets/photos/ombre/thumbnails/three_quarter_tip.jpg'),
      ],
      setup: PICTURE_SETUP.FOUR_BOXES,
    },
    {
      images: [
        require('../../assets/photos/ombre/thumbnails/triangles.jpg'),
        require('../../assets/photos/ombre/thumbnails/vampire.jpg'),
      ],
      setup: PICTURE_SETUP.SIDE_BY_SIDE,
    },
    {
      images: [
        require('../../assets/photos/ombre/thumbnails/light_tip.jpg'),
      ],
      setup: PICTURE_SETUP.DEFAULT,
    },
    {
      images: [
        require('../../assets/photos/ombre/thumbnails/orange_glitter.jpg'),
      ],
      setup: PICTURE_SETUP.DEFAULT,
    },
    {
      images: [
        require('../../assets/photos/ombre/thumbnails/flat.jpg'),
        require('../../assets/photos/ombre/thumbnails/grey_slash.jpg'),
      ],
      setup: PICTURE_SETUP.TOP_BOTTOM,
    },
  ],
  seasonal: [
    {
      images: [
        require('../../assets/photos/seasonal/thumbnails/black_pumpkins.jpg'),
        require('../../assets/photos/seasonal/thumbnails/america.jpg'),
        require('../../assets/photos/seasonal/thumbnails/easter.jpg'),
      ],
      setup: PICTURE_SETUP.ONE_TOP_TWO_BOTTOM,
    },
    {
      images: [
        require('../../assets/photos/seasonal/thumbnails/frank.jpg'),
        require('../../assets/photos/seasonal/thumbnails/franks_bride.jpg'),
      ],
      setup: PICTURE_SETUP.TOP_BOTTOM,
    },
    {
      images: [
        require('../../assets/photos/seasonal/thumbnails/blood_slash.jpg'),
        require('../../assets/photos/seasonal/thumbnails/christmas_glitter.jpg'),
        require('../../assets/photos/seasonal/thumbnails/christmas_simple.jpg'),
        require('../../assets/photos/seasonal/thumbnails/fall_leaves.jpg'),
      ],
      setup: PICTURE_SETUP.FOUR_BOXES,
    },
    {
      images: [
        require('../../assets/photos/seasonal/thumbnails/fun_halloween.jpg'),
      ],
      setup: PICTURE_SETUP.DEFAULT,
    },
    {
      images: [
        require('../../assets/photos/seasonal/thumbnails/golden_cats.jpg'),
        require('../../assets/photos/seasonal/thumbnails/golden_halloween.jpg'),
      ],
      setup: PICTURE_SETUP.SIDE_BY_SIDE,
    },
    {
      images: [
        require('../../assets/photos/seasonal/thumbnails/halloween_characters.jpg'),
        require('../../assets/photos/seasonal/thumbnails/irish.jpg'),
      ],
      setup: PICTURE_SETUP.SIDE_BY_SIDE,
    },
    {
      images: [
        require('../../assets/photos/seasonal/thumbnails/presents.jpg'),
        require('../../assets/photos/seasonal/thumbnails/oktoberfest_1.jpg'),
        require('../../assets/photos/seasonal/thumbnails/oktoberfest_2.jpg'),
      ],
      setup: PICTURE_SETUP.ONE_LEFT_TWO_RIGHT,
    },
    {
      images: [
        require('../../assets/photos/seasonal/thumbnails/orange_pumpkins.jpg'),
        require('../../assets/photos/seasonal/thumbnails/hearts.jpg'),
      ],
      setup: PICTURE_SETUP.TOP_BOTTOM,
    },
    {
      images: [
        require('../../assets/photos/seasonal/thumbnails/two_hand_spiders.jpg'),
        require('../../assets/photos/seasonal/thumbnails/other_golden_halloween.jpg'),
        require('../../assets/photos/seasonal/thumbnails/santa.jpg'),
      ],
      setup: PICTURE_SETUP.ONE_TOP_TWO_BOTTOM,
    },
    {
      images: [
        require('../../assets/photos/seasonal/thumbnails/silver_skulls.jpg'),
        require('../../assets/photos/seasonal/thumbnails/spring.jpg'),
        require('../../assets/photos/seasonal/thumbnails/spider_webs.jpg'),
      ],
      setup: PICTURE_SETUP.TWO_TOP_ONE_BOTTOM,
    },
    {
      images: [
        require('../../assets/photos/seasonal/thumbnails/goo.jpg'),
        require('../../assets/photos/seasonal/thumbnails/snowflakes.jpg'),
      ],
      setup: PICTURE_SETUP.TOP_BOTTOM,
    },
    {
      images: [
        require('../../assets/photos/seasonal/thumbnails/xmas_1.jpg'),
        require('../../assets/photos/seasonal/thumbnails/nightmare_before_xmas.jpg'),
        require('../../assets/photos/seasonal/thumbnails/face_pumpkins.jpg'),
      ],
      setup: PICTURE_SETUP.THREE_TOP_TO_BOTTOM,
    },
  ],
  themed: [
    {
      images: [
        require('../../assets/photos/themed/thumbnails/all_glitter.jpg'),
        require('../../assets/photos/themed/thumbnails/britain.jpg'),
        require('../../assets/photos/themed/thumbnails/rainbow_glitter.jpg'),
      ],
      setup: PICTURE_SETUP.TWO_LEFT_ONE_RIGHT,
    },
    {
      images: [
        require('../../assets/photos/themed/thumbnails/chiefs.jpg'),
        require('../../assets/photos/themed/thumbnails/braves.jpg'),
        require('../../assets/photos/themed/thumbnails/rams.jpg'),
      ],
      setup: PICTURE_SETUP.ONE_TOP_TWO_BOTTOM,
    },
    {
      images: [
        require('../../assets/photos/themed/thumbnails/clouds.jpg'),
        require('../../assets/photos/themed/thumbnails/design.jpg'),
        require('../../assets/photos/themed/thumbnails/fringe_nails.jpg'),
        require('../../assets/photos/themed/thumbnails/lime_lines.jpg'),
      ],
      setup: PICTURE_SETUP.FOUR_BOXES,
    },
    {
      images: [
        require('../../assets/photos/themed/thumbnails/egyptian.jpg'),
      ],
      setup: PICTURE_SETUP.DEFAULT,
    },
    {
      images: [
        require('../../assets/photos/themed/thumbnails/unicorns.jpg'),
        require('../../assets/photos/themed/thumbnails/marble_paint.jpg'),
      ],
      setup: PICTURE_SETUP.TOP_BOTTOM,
    },
    {
      images: [
        require('../../assets/photos/themed/thumbnails/adventure_time.jpg'),
        require('../../assets/photos/themed/thumbnails/cherry_blossom.jpg'),
      ],
      setup: PICTURE_SETUP.SIDE_BY_SIDE,
    },
    {
      images: [
        require('../../assets/photos/themed/thumbnails/joy.jpg'),
      ],
      setup: PICTURE_SETUP.DEFAULT,
    },
    {
      images: [
        require('../../assets/photos/themed/thumbnails/dots.jpg'),
        require('../../assets/photos/themed/thumbnails/feather.jpg'),
        require('../../assets/photos/themed/thumbnails/marble.jpg'),
      ],
      setup: PICTURE_SETUP.TWO_TOP_ONE_BOTTOM,
    },
    {
      images: [
        require('../../assets/photos/themed/thumbnails/red_star.jpg'),
        require('../../assets/photos/themed/thumbnails/marble_scarce.jpg'),
        require('../../assets/photos/themed/thumbnails/mosaic.jpg'),
      ],
      setup: PICTURE_SETUP.ONE_LEFT_TWO_RIGHT,
    },
    {
      images: [
        require('../../assets/photos/themed/thumbnails/palms.jpg'),
        require('../../assets/photos/themed/thumbnails/pink_palms.jpg'),
        require('../../assets/photos/themed/thumbnails/stars.jpg'),
      ],
      setup: PICTURE_SETUP.TWO_TOP_ONE_BOTTOM,
    },
    {
      images: [
        require('../../assets/photos/themed/thumbnails/peach_and_glitter.jpg'),
      ],
      setup: PICTURE_SETUP.DEFAULT,
    },
    {
      images: [
        require('../../assets/photos/themed/thumbnails/stranger_things.jpg'),
        require('../../assets/photos/themed/thumbnails/rando.jpg'),
        require('../../assets/photos/themed/thumbnails/space.jpg'),
      ],
      setup: PICTURE_SETUP.ONE_TOP_TWO_BOTTOM,
    },
    {
      images: [
        require('../../assets/photos/themed/thumbnails/renn_1.jpg'),
        require('../../assets/photos/themed/thumbnails/renn_2.jpg'),
        require('../../assets/photos/themed/thumbnails/superheroes.jpg'),
      ],
      setup: PICTURE_SETUP.TWO_TOP_ONE_BOTTOM,
    },
    {
      images: [
        require('../../assets/photos/themed/thumbnails/treasure_map_1.jpg'),
        require('../../assets/photos/themed/thumbnails/treasure_map_2.jpg'),
      ],
      setup: PICTURE_SETUP.TOP_BOTTOM,
    },
    {
      images: [
        require('../../assets/photos/themed/thumbnails/zelda_hearts.jpg'),
      ],
      setup: PICTURE_SETUP.DEFAULT,
    },
    {
      images: [
        require('../../assets/photos/themed/thumbnails/planets.jpg'),
      ],
      setup: PICTURE_SETUP.DEFAULT,
    },
  ],
};

export default PHOTO_GROUPS;

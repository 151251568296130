<template>
  <div class="bn-container">
    <div class="bn-column bn-card service-text bn-site-background-color bn-site-color" :class="{'bn-sticky': !isLargeMode}">
      <div class="intimate-text bn-luxury">Beautiful</div>
      <div class="one-on-one-text bn-arial">Designs</div>
    </div>
    <div class="bn-column bn-card bn-site-background-color" :class="{'bn-sticky': !isLargeMode}">
      <photo-card :images="frenchArt" :setup="PICTURE_SETUP.ONE_LEFT_TWO_RIGHT" />
    </div>
    <div class="bn-column bn-card service-text bn-site-background-color bn-site-color" :class="{'bn-sticky': !isLargeMode}">
      <div class="intimate-text bn-luxury">Custom</div>
      <div class="one-on-one-text bn-arial">Nail Art</div>
    </div>
    <div class="bn-column bn-card bn-site-background-color" :class="{'bn-sticky': !isLargeMode}">
      <photo-card :images="customArt" :setup="PICTURE_SETUP.TWO_LEFT_ONE_RIGHT" />
    </div>
    <div class="bn-column bn-card service-text bn-site-background-color bn-site-color" :class="{'bn-sticky': !isLargeMode}">
      <div class="intimate-text bn-luxury">Intimate</div>
      <div class="one-on-one-text bn-arial">One on One</div>
      <div class="one-on-one-text bn-arial">Service</div>
    </div>
    <div class="bn-column bn-card bn-site-background-color" :class="{'bn-sticky': !isLargeMode}">
      <div class="bn-column bn-card bn-site-background-color" :class="{'bn-sticky': !isLargeMode}">
        <photo-card :images="salonArt" :setup="PICTURE_SETUP.ONE_TOP_TWO_BOTTOM" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import PhotoCard from '@/components/PhotoCard.vue';
import PICTURE_SETUP from '@/enums/PictureSetup';
import TOP_SCROLL from '@/mixins/topScroll';

export default {
  name: 'Home',
  components: {
    PhotoCard,
  },
  mixins: [
    TOP_SCROLL,
  ],
  data() {
    return {
      divCardHeight: window.innerHeight,
      PICTURE_SETUP,
    };
  },
  computed: {
    customArt() {
      return [
        require('../../assets/displays/custom/heart_shapes.jpg'),
        require('../../assets/displays/custom/nightmare_before_christmas.jpg'),
        require('../../assets/displays/custom/palm_trees.jpg'),
      ];
    },
    divHeightPx() {
      return `height: ${this.divCardHeight}px;`;
    },
    frenchArt() {
      return [
        require('../../assets/displays/french/red_big.jpg'),
        require('../../assets/displays/french/purple_small.jpg'),
        require('../../assets/displays/french/orange_small.jpg'),
      ];
    },
    salonArt() {
      return [
        require('../../assets/displays/salon/salon1.jpg'),
        require('../../assets/displays/salon/salon2.jpg'),
        require('../../assets/displays/salon/salon3.jpg'),
      ];
    },
    textCardHeight() {
      return this.divCardHeight / 3;
    },
    textHeightPx() {
      return `height: ${this.textCardHeight}px;`;
    },
    ...mapGetters([
      'isLargeMode',
    ]),
  },
  created() {    
    window.addEventListener('resize', () => {
      this.divCardHeight = window.innerHeight;
    });
  },
};
</script>

<style lang="scss" scoped>
.header {
  z-index: 0;
  --width-mobile: 12;
}

.info {
  z-index: 100;
  --width-mobile: 12;
  --width-tablet: 4;
}

.intimate-text {
  font-size: 76px;
}

.one-on-one-text {
  font-size: 32px;
}

.service-text {
  padding-bottom: 40px;
  padding-top: 30px; 
}
</style>

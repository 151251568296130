const ProfitData = {
  getters: {
    
  },
  actions: {
    addNewProfit(context, profit) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverAddNewProfit', profit)
            .then((newProfit) => resolve(newProfit));
        } catch {
          reject(null);
        }
      });
    },
    deleteProfit(context, id) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverDeleteProfit', id)
            .then((isSuccess) => resolve(isSuccess));
        } catch {
          reject(false);
        }
      });
    },
    getProfit(context, id) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverGetProfit', id)
            .then((profit) => resolve(profit));
        } catch {
          reject(null);
        }
      });
    },
    getProfits(context, dateQuery) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverGetProfits', dateQuery)
            .then((profits) => resolve(profits));
        } catch {
          reject([]);
        }
      });
    },
    updateProfit(context, updatedProfit) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverUpdateProfit', updatedProfit)
            .then((profit) => resolve(profit));
        } catch {
          reject(null);
        }
      });
    },
  },
  mutations: {
    
  },
  state: {
    
  },
};

export default ProfitData;

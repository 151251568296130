<template>
  <div v-if="isLoggedIn" class="bn-container bn-site-color bn-site-background-color parent-container">
    <div class="bn-column bn-card bn-site-background-color" :class="{'popup': isModalActive}">
      <div class="bn-column bn-luxury admin-title">Payments</div>
      <div class="bn-container content-container">
        <div :class="{'month-selector-large': isLargeMode, 'month-selector-small': !isLargeMode}">
          <b-field label="Date" label-position="inside">
            <b-datepicker
              type="month"
              icon="calendar-today"
              position="is-bottom-left"
              size="is-small"
              v-model="selectedDate"
              :mobile-native="false"
              trap-focus />
          </b-field>
        </div>
        <b-table
          class="payments-table"
          :loading="isLoading"
          :data="payments"
          :striped="true"
          :narrowed="true"
          :hoverable="true"
          :selected.sync="selected"
          :mobile-cards="true">
          <b-table-column label="Payment" v-slot="props">
            {{ props.row.paymentDate }}
          </b-table-column>
          <b-table-column label="%" v-slot="props">
            {{ props.row.percentage }}
          </b-table-column>
          <b-table-column label="Paid" v-slot="props">
            {{ props.row.paid | toCurrency }}
          </b-table-column>
          <b-table-column label="Paid On" v-slot="props">
            {{ props.row.paidDate | toDate }}
          </b-table-column>
        </b-table>
        <div class="generate-buttons">
          <b-button :type="danger15th" @click="generate15th">Generate 15th</b-button>
          <b-button :type="dangerLast" @click="generateLast">
            Generate {{ `${lastDayOfMonth}${lastDayOfMonthEnding}` }}
          </b-button>
        </div>
      </div>
    </div>
    <b-button class="admin-button" @click="$router.push('/admin')">
      <i class="fa fa-arrow-left"></i> ADMIN
    </b-button>
    <accept-payment :isModalActive="isModalActive" :paymentId="paymentId" @close="isModalActive = false" />
  </div>
  <div v-else>
    <br><br><br><br><br><br><br><br><br><br><br><br>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters } from 'vuex';

import { failure, success } from '@/utility/messages';
import AcceptPayment from '@/components/subcomponent/AcceptPayment';
import ADMIN_ACCESS from '@/mixins/adminAccess';
import HOT_RELOAD from '@/mixins/hotReload';
import DateQuery from '@/models/dateQuery';

export default Vue.extend({
  name: 'Payments',
  mixins: [
    ADMIN_ACCESS,
    HOT_RELOAD,
  ],
  components: {
    AcceptPayment,
  },
  data() {
    return {
      isLoading: false,
      isModalActive: false,
      paymentId: '',
      payments: [],
      selected: null,
      selectedDate: new Date(),
    };
  },
  computed: {
    dangerLast() {
      return this.payments.find((pay) => pay.paymentDate !== '15th') ? 'is-danger' : '';
    },
    danger15th() {
      return this.payments.find((pay) => pay.paymentDate === '15th') ? 'is-danger' : '';
    },
    lastDayOfMonth() {
      if (!this.selectedDate) return '28';
      return new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth() + 1, 0).getDate();
    },
    lastDayOfMonthEnding() {
      const num = Number(this.lastDayOfMonth);
      return isNaN(num) ? '' : num === 31 ? 'st' : 'th';
    },
    ...mapGetters([
      'isLargeMode',
    ]),
  },
  watch: {
    isModalActive(newVal) {
      this.$store.dispatch('modalOpen', newVal);
    },
    selected(val) {
      if (!val || !val.id) return;
      this.paymentId = val.id;
      this.isModalActive = true;
    },
    selectedDate(val) {
      if (!val) return;
      this.getData();
    }
  },
  created() {
    this.getData();
  },
  methods: {
    generateLast(e, overwrite) {
      if (!overwrite && this.dangerLast) {
        this.promptOverwrite(this.generateLast);
      } else {
        const that = this;
        const date = new Date(this.selectedDate);

        this.promptPercentage(
          (percentage) => {
            this.isLoading = true;
            this.$store.dispatch('createPayment', {
              day: `${this.lastDayOfMonth}${this.lastDayOfMonthEnding}`,
              month: date.getMonth(),
              year: date.getFullYear(),
              percentage,
            })
            .then((payment) => {
              if (payment) {
                this.$store.dispatch('hotReload');
                success(that, {message: 'Created payment.'});
              } else {
                failure(that, {message: 'Error creating payment.'});
              }
            })
            .catch(() => failure(that, {message: 'Error creating payment.'}))
            .finally(() => this.isLoading = false);
          }
        );
      }
    },
    generate15th(e, overwrite) {
      if (!overwrite && this.danger15th) {
        this.promptOverwrite(this.generate15th);
      } else {
        const that = this;
        const date = new Date(this.selectedDate);

        this.promptPercentage(
          (percentage) => {
            this.isLoading = true;
            this.$store.dispatch('createPayment', {
              day: '15th',
              month: date.getMonth(),
              year: date.getFullYear(),
              percentage,
            })
            .then((payment) => {
              if (payment) {
                this.$store.dispatch('hotReload');
                success(that, {message: 'Created payment.'});
              } else {
                failure(that, {message: 'Error creating payment.'});
              }
            })
            .catch(() => failure(that, {message: 'Error creating payment.'}))
            .finally(() => this.isLoading = false);
          }
        );
      }
    },
    getData() {
      if (!this.selectedDate) return;

      this.isLoading = true;

      const date = new Date(this.selectedDate);
      const month = date.getMonth();
      const year = date.getFullYear();
      const dateQuery = new DateQuery(year, month);

      this.$store.dispatch('getPayments', dateQuery)
        .then((payments) => this.payments = payments)
        .catch(() => failure(this, {message: 'Error retrieving payments.'}))
        .finally(() => this.isLoading = false);
    },
    promptOverwrite(func) {
      this.$buefy.dialog.prompt({
        message: 'Are you sure you want to recalculate the payment?',
        inputAttrs: {
          placeholder: 'Type "YES" to recalculate',
          maxlength: 3,
        },
        trapFocus: true,
        onConfirm: (value) => value === 'YES' ? func(null, true) : func(null, false),
      });
    },
    promptPercentage(func) {
      this.$buefy.dialog.prompt({
        message: 'What percentage to pay?',
        inputAttrs: {
          type: 'number',
          placeholder: '40',
          maxlength: 3,
          max: 100,
          min: 0,
        },
        trapFocus: true,
        onConfirm: (value) => func(value),
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.admin-button {
  float: left;
  margin-left: 12px;
}

.admin-title {
  font-size: 60px;
  padding-bottom: 28px;
  padding-top: 28px;
}

.content-container {
  padding: 0px 12px;
  position: relative;
}

.generate-buttons {
  margin-left: auto;
  padding-bottom: 24px;

  button:first-child {
    margin-right: 8px;
  }
}

.month-selector-large {
  position: absolute;
  right: 12px;
  top: -44px;
}

.month-selector-small {
  padding-bottom: 8px;
}

.parent-container {
  z-index: 30;
}

.payments-table {
  cursor: pointer;
  padding-bottom: 24px;
  text-align: left;
  width: 100%;
}

.popup {
  z-index: 0 !important;
}
</style>

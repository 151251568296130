const Rollup = (isProfit, title, cost, tip = null) => (
  {
    isProfit,
    title,
    cost,
    tip,
  }
);

export default Rollup;

import Vue from 'vue';
import VueRouter from 'vue-router';

import Home from '@/views/Home.vue';
import LINKS from '@/config/links';

Vue.use(VueRouter);

const buildRoutes = (links) => {
  const home = {
    path: '/',
    name: 'Home',
    component: Home,
  };
  const catchAll = {
    // this is added here to catch all 404s
    path: '/*',
    name: 'Home',
    component: Home,
  };

  if (!links || !(links instanceof Array)) return [home, catchAll];
  
  const routes = links.map((link) => ({
    component: link.component,
    name: link.title,
    path: link.link,
  }));

  return [home, ...routes, catchAll];
};

/** The below is left for ideas, concepts, etc. */
// {
//   path: '/about',
//   name: 'About',
//   // route level code-splitting
//   // this generates a separate chunk (about.[hash].js) for this route
//   // which is lazy-loaded when the route is visited.
//   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
// },
// {
//   path: '/contact',
//   name: 'Contact',
//   component: () => import('../views/Contact.vue'),
// },

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: buildRoutes(LINKS),
});

export default router;

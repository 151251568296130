<template>
  <div 
    v-if="isModalOpen"
    id="modal"
    class="modal"
  >
    <div class="modal-content">
      <div class="modal-header">
        <span class="close" @click="closeModal">&times;</span>
      </div>
      <div class="modal-body">
        <component :is="getContent" v-bind="getProps" />
      </div>
      <div class="modal-footer">
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'Modal',
  computed: {
    ...mapGetters([
      'getContent',
      'getProps',
      'isModalOpen',
    ]),
  },
  created() {
    window.onclick = (event) => {
      if (event.target && event.target.id === 'modal') {
        this.$store.dispatch('closeModal');
      }
    };
  },
  methods: {
    closeModal() {
      this.$store.dispatch('closeModal');
    },
  },
});
</script>

<style scoped>
@-webkit-keyframes animatetop {
  from {top:-300px; opacity:0} 
  to {top:0; opacity:1}
}

@keyframes animatetop {
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
}

.close {
  color: #aaaaaa;
  font-size: 28px;
  font-weight: bold;
  position: absolute;
  right: 16px;
  top: 12px;
}

.close:hover, .close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal {
  background-color: rgba(0,0,0,0.4);
  display: block;
  height: 100%;
  left: 0;
  overflow: auto;
  padding-top: 100px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2000;
}

.modal-body {
  padding: 4px 4px 4px 4px;
}

.modal-content {
  background-image: linear-gradient(200deg, black, white);
  animation-duration: 0.4s;
  animation-name: animatetop;
  margin: auto;
  max-width: 80%;
  padding: 8px;
  position: relative;
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
}

.modal-footer {
  padding: 4px 4px 4px 4px;
}

.modal-header {
  padding: 4px 4px 4px 4px;
}
</style>

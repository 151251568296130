import { mapGetters } from 'vuex';

const HOT_RELOAD = {
  computed: {
    ...mapGetters([
      'hotReload',
    ]),
  },

  watch: {
    hotReload(val) {
      if (!val)
      this.getData();
      this.$store.dispatch('coldReload');
    },
  },
};

export default HOT_RELOAD;

<template>
  <div v-if="isLoggedIn" class="bn-container bn-site-color bn-site-background-color">
    <div class="bn-column bn-card bn-site-background-color" :class="{'popup': isModalActive}">
      <div class="bn-column bn-luxury admin-title">Clients</div>
      <div class="bn-container content-container">
        <div class="bn-column">
          <span>Total Clients: {{ allClients.length }}</span>
          <b-table
            class="clients-table"
            :loading="isLoading"
            :data="allClients"
            :striped="true"
            :narrowed="true"
            :hoverable="true"
            :selected.sync="selected"
            :mobile-cards="true">
            <b-table-column label="First Name" v-slot="props">
              {{ props.row.firstName }}
            </b-table-column>
            <b-table-column label="Last Name" v-slot="props">
              {{ props.row.lastName }}
            </b-table-column>
            <b-table-column label="Phone Number" v-slot="props">
              {{ props.row.phoneNumber }}
            </b-table-column>
            <b-table-column label="Comments" v-slot="props">
              {{ props.row.comments }}
            </b-table-column>
          </b-table>
          <b-button class="add-button" @click="addNewClient">Add</b-button>
        </div>
      </div>
    </div>
    <b-button class="admin-button" @click="$router.push('/admin')">
      <i class="fa fa-arrow-left"></i> ADMIN
    </b-button>
    <client-data :clientId="clientId" :isModalActive="isModalActive" @close="isModalActive = false" />
  </div>
  <div v-else>
    <br><br><br><br><br><br><br><br><br><br><br><br>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';

import { failure } from '@/utility/messages';
import ADMIN_ACCESS from '@/mixins/adminAccess';
import ClientData from '@/components/subcomponent/ClientData.vue';
import HOT_RELOAD from '@/mixins/hotReload';

export default Vue.extend({
  name: 'Clients',
  mixins: [
    ADMIN_ACCESS,
    HOT_RELOAD,
  ],
  components: {
    ClientData,
  },
  data() {
    return {
      allClients: [],
      clientId: '',
      isEdit: false,
      isLoading: false,
      isModalActive: false,
      selected: null,
    };
  },
  watch: {
    isModalActive(newVal) {
      this.$store.dispatch('modalOpen', newVal);
    },
    selected(val) {
      if (!val) return;
      this.editClient(val);
      this.selected = null;
    },
  },
  created() {
    this.getData();
  },
  methods: {
    addNewClient() {
      this.clientId = '';
      this.isModalActive = true;
    },
    editClient({ id }) {
      this.clientId = id;
      this.isModalActive = true;
    },
    getData() {
      this.isLoading = true;
      this.$store.dispatch('getAllClients')
        .then((clients) => this.allClients = clients)
        .catch(() => failure(this, {message: 'Error retrieving clients.'}))
        .finally(() => this.isLoading = false);
    },
  },
});
</script>

<style lang="scss" scoped>
.add-button {
  float: right;
  margin-top: 8px;
}

.admin-button {
  float: left;
  margin-left: 12px;
}

.admin-title {
  font-size: 72px;
  padding-bottom: 28px;
  padding-top: 28px;
}

.clients-table {
  cursor: pointer;
  max-height: 500px;
  overflow-y: scroll;
  text-align: left;
  width: 100%;
}

.content-container {
  padding: 0px 12px;
  position: relative;
}

.popup {
  z-index: 0 !important;
}
</style>

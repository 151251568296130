/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPayment = /* GraphQL */ `
  mutation CreatePayment(
    $input: CreatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    createPayment(input: $input, condition: $condition) {
      id
      paymentDate
      percentage
      paid
      paidDate
      month
      year
      createdOn
      editedOn
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updatePayment = /* GraphQL */ `
  mutation UpdatePayment(
    $input: UpdatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    updatePayment(input: $input, condition: $condition) {
      id
      paymentDate
      percentage
      paid
      paidDate
      month
      year
      createdOn
      editedOn
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deletePayment = /* GraphQL */ `
  mutation DeletePayment(
    $input: DeletePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    deletePayment(input: $input, condition: $condition) {
      id
      paymentDate
      percentage
      paid
      paidDate
      month
      year
      createdOn
      editedOn
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createGiftCard = /* GraphQL */ `
  mutation CreateGiftCard(
    $input: CreateGiftCardInput!
    $condition: ModelGiftCardConditionInput
  ) {
    createGiftCard(input: $input, condition: $condition) {
      id
      initialTotal
      tip
      remaining
      service
      remainingServices
      year
      day
      month
      cardId
      createdOn
      editedOn
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateGiftCard = /* GraphQL */ `
  mutation UpdateGiftCard(
    $input: UpdateGiftCardInput!
    $condition: ModelGiftCardConditionInput
  ) {
    updateGiftCard(input: $input, condition: $condition) {
      id
      initialTotal
      tip
      remaining
      service
      remainingServices
      year
      day
      month
      cardId
      createdOn
      editedOn
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteGiftCard = /* GraphQL */ `
  mutation DeleteGiftCard(
    $input: DeleteGiftCardInput!
    $condition: ModelGiftCardConditionInput
  ) {
    deleteGiftCard(input: $input, condition: $condition) {
      id
      initialTotal
      tip
      remaining
      service
      remainingServices
      year
      day
      month
      cardId
      createdOn
      editedOn
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createExpense = /* GraphQL */ `
  mutation CreateExpense(
    $input: CreateExpenseInput!
    $condition: ModelExpenseConditionInput
  ) {
    createExpense(input: $input, condition: $condition) {
      id
      expense
      cost
      month
      year
      profitId
      profit {
        id
        service
        clientId
        client {
          id
          firstName
          lastName
          phoneNumber
          comments
          createdAt
          updatedAt
          owner
        }
        serviceDate
        tips
        cost
        month
        year
        day
        createdOn
        editedOn
        createdAt
        updatedAt
        owner
      }
      createdOn
      editedOn
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateExpense = /* GraphQL */ `
  mutation UpdateExpense(
    $input: UpdateExpenseInput!
    $condition: ModelExpenseConditionInput
  ) {
    updateExpense(input: $input, condition: $condition) {
      id
      expense
      cost
      month
      year
      profitId
      profit {
        id
        service
        clientId
        client {
          id
          firstName
          lastName
          phoneNumber
          comments
          createdAt
          updatedAt
          owner
        }
        serviceDate
        tips
        cost
        month
        year
        day
        createdOn
        editedOn
        createdAt
        updatedAt
        owner
      }
      createdOn
      editedOn
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteExpense = /* GraphQL */ `
  mutation DeleteExpense(
    $input: DeleteExpenseInput!
    $condition: ModelExpenseConditionInput
  ) {
    deleteExpense(input: $input, condition: $condition) {
      id
      expense
      cost
      month
      year
      profitId
      profit {
        id
        service
        clientId
        client {
          id
          firstName
          lastName
          phoneNumber
          comments
          createdAt
          updatedAt
          owner
        }
        serviceDate
        tips
        cost
        month
        year
        day
        createdOn
        editedOn
        createdAt
        updatedAt
        owner
      }
      createdOn
      editedOn
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createProfit = /* GraphQL */ `
  mutation CreateProfit(
    $input: CreateProfitInput!
    $condition: ModelProfitConditionInput
  ) {
    createProfit(input: $input, condition: $condition) {
      id
      service
      clientId
      client {
        id
        firstName
        lastName
        phoneNumber
        comments
        createdAt
        updatedAt
        owner
      }
      serviceDate
      tips
      cost
      month
      year
      day
      createdOn
      editedOn
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateProfit = /* GraphQL */ `
  mutation UpdateProfit(
    $input: UpdateProfitInput!
    $condition: ModelProfitConditionInput
  ) {
    updateProfit(input: $input, condition: $condition) {
      id
      service
      clientId
      client {
        id
        firstName
        lastName
        phoneNumber
        comments
        createdAt
        updatedAt
        owner
      }
      serviceDate
      tips
      cost
      month
      year
      day
      createdOn
      editedOn
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteProfit = /* GraphQL */ `
  mutation DeleteProfit(
    $input: DeleteProfitInput!
    $condition: ModelProfitConditionInput
  ) {
    deleteProfit(input: $input, condition: $condition) {
      id
      service
      clientId
      client {
        id
        firstName
        lastName
        phoneNumber
        comments
        createdAt
        updatedAt
        owner
      }
      serviceDate
      tips
      cost
      month
      year
      day
      createdOn
      editedOn
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createClient = /* GraphQL */ `
  mutation CreateClient(
    $input: CreateClientInput!
    $condition: ModelClientConditionInput
  ) {
    createClient(input: $input, condition: $condition) {
      id
      firstName
      lastName
      phoneNumber
      comments
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateClient = /* GraphQL */ `
  mutation UpdateClient(
    $input: UpdateClientInput!
    $condition: ModelClientConditionInput
  ) {
    updateClient(input: $input, condition: $condition) {
      id
      firstName
      lastName
      phoneNumber
      comments
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteClient = /* GraphQL */ `
  mutation DeleteClient(
    $input: DeleteClientInput!
    $condition: ModelClientConditionInput
  ) {
    deleteClient(input: $input, condition: $condition) {
      id
      firstName
      lastName
      phoneNumber
      comments
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateAppointment = /* GraphQL */ `
  mutation UpdateAppointment(
    $input: UpdateAppointmentInput!
    $condition: ModelAppointmentConditionInput
  ) {
    updateAppointment(input: $input, condition: $condition) {
      id
      firstName
      lastName
      phoneNumber
      candle
      music
      services
      comments
      requestedFor
      createdOn
      editedOn
      status
      Client {
        id
        firstName
        lastName
        phoneNumber
        comments
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteAppointment = /* GraphQL */ `
  mutation DeleteAppointment(
    $input: DeleteAppointmentInput!
    $condition: ModelAppointmentConditionInput
  ) {
    deleteAppointment(input: $input, condition: $condition) {
      id
      firstName
      lastName
      phoneNumber
      candle
      music
      services
      comments
      requestedFor
      createdOn
      editedOn
      status
      Client {
        id
        firstName
        lastName
        phoneNumber
        comments
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createAppointment = /* GraphQL */ `
  mutation CreateAppointment(
    $input: CreateAppointmentInput!
    $condition: ModelAppointmentConditionInput
  ) {
    createAppointment(input: $input, condition: $condition) {
      id
      firstName
      lastName
      phoneNumber
      candle
      music
      services
      comments
      requestedFor
      createdOn
      editedOn
      status
      Client {
        id
        firstName
        lastName
        phoneNumber
        comments
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;

<template>
  <div class="bn-container bn-site-color">
    <div class="bn-column bn-card bn-site-background-color">
      <div class="pricing-header">Pricing</div>
      <span>
        See what your custom experience may cost.<br>
        Call or text for specific costs and custom art.<br>
        Pricing does not include any tips.
      </span>
    </div>    
    <div class="bn-container bn-column bn-card pricing-table-container bn-site-background-color">
      <div class="bn-column larger-box">
        <div class="pricing-table-header">Basic Services</div>
        <table class="pricing-table">
          <tr>
            <th class="pricing-cart bn-site-color"><i class="fa fa-shopping-cart"></i></th>
            <th class="bn-site-color">Service</th>
            <th class="pricing-cost bn-site-color">Cost</th>
          </tr>
          <tr v-for="service in BASIC_SERVICES" :key="service.id">
            <td>
              <div v-if="serviceIndex(service) > -1" class="pricing-button" @click="removeService(service)">
                <i class="fa fa-minus"></i>
              </div>
              <div v-else class="pricing-button" :class="{'pricing-grow': grow === service.id}" @click="addService(service)">
                <i class="fa fa-plus"></i>
              </div>
            </td>
            <td>{{ service.service }}</td>
            <td>${{ service.cost }}</td>
          </tr>
        </table>
        <div class="pricing-table-header">Discounts</div>
        <table class="pricing-table">
          <tr>
            <th class="pricing-cart bn-site-color"><i class="fa fa-shopping-cart"></i></th>
            <th class="bn-site-color">Discount</th>
            <th class="pricing-cost bn-site-color">Cost</th>
          </tr>
          <tr v-for="discount in DISCOUNTS" :key="discount.id">
            <td>
              <div v-if="discountIndex(discount) > -1" class="pricing-button" @click="removeDiscount(discount)">
                <i class="fa fa-minus"></i>
              </div>
              <div v-else class="pricing-button" :class="{'pricing-grow': grow === discount.id}" @click="addDiscount(discount)">
                <i class="fa fa-plus"></i>
              </div>
            </td>
            <td>{{ discount.service }}</td>
            <td>${{ discount.cost }}</td>
          </tr>
        </table>
        <div class="pricing-table-header">Artwork</div>
        <table class="pricing-table">
          <tr>
            <th class="pricing-cart bn-site-color"><i class="fa fa-shopping-cart"></i></th>
            <th class="bn-site-color">Artwork</th>
          </tr>
          <tr v-for="art in ARTWORK" :key="art.id">
            <td>
              <div v-if="artworkIndex(art) > -1" class="pricing-button" @click="removeArtwork(art)">
                <i class="fa fa-minus"></i>
              </div>
              <div v-else class="pricing-button" :class="{'pricing-grow': grow === art.id}" @click="addArtwork(art)">
                <i class="fa fa-plus"></i>
              </div>
            </td>
            <td>
              <b-tooltip
                class="service-icon"
                position="is-right"
                size="is-small"
                type="is-light"
                :label="art.text"
                multilined>
                {{ art.service }}
                <i :class="art.icon" />
              </b-tooltip>
            </td>
          </tr>
        </table>
      </div>
      <div class="bn-column pricing-empty">&nbsp;</div>
      <div class="bn-column smaller-box">
        <div :class="{'pricing-container-large': isLargeMode, 'pricing-container-small': !isLargeMode}">
          <div class="pricing-table-header">Totals</div>
          <table class="pricing-table">
            <tr>
              <th class="pricing bn-site-color" colspan="2">Pricing</th>
            </tr>
            <tr>
              <td class="pricing-sub-header" colspan="2">Services:</td>
            </tr>
            <tr v-for="service in services" :key="service.id">
              <td v-if="isLargeMode" class="large-item">{{ service.service }}</td>
              <td v-else class="small-item">{{ service.short }}</td>
              <td v-if="isLargeMode" class="large-value">${{ service.cost }}</td>
              <td v-else class="small-value">${{ service.cost }}</td>
            </tr>
            <tr>
              <td class="pricing-sub-header" colspan="2">Artwork:</td>
            </tr>
            <tr v-for="art in artwork" :key="art.id">
              <td v-if="isLargeMode" class="large-item">{{ art.service }}</td>
              <td v-else class="small-item">{{ art.short }}</td>
              <td v-if="isLargeMode" class="large-value">${{ art.cost }}</td>
              <td v-else class="small-value">${{ art.cost }}</td>
            </tr>
            <tr>
              <td class="pricing-sub-header" colspan="2">Discounts:</td>
            </tr>
            <tr v-for="discount in discounts" :key="discount.id">
              <td v-if="isLargeMode" class="large-item">{{ discount.service }}</td>
              <td v-else class="small-item">{{ discount.short }}</td>
              <td v-if="isLargeMode" class="large-value">${{ discount.cost }}</td>
              <td v-else class="small-value">${{ discount.cost }}</td>
            </tr>
            <tr>
              <td class="pricing-sub-header" colspan="2">Total:</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td v-if="total <= 0">&nbsp;</td>
              <td v-else :class="{'large-value': isLargeMode, 'small-value': !isLargeMode}">${{ total }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters } from 'vuex';

import ARTWORK from '@/config/artwork';
import ArtworkPriceSelection from '@/components/subcomponent/ArtworkPriceSelection.vue';
import BASIC_SERVICES from '@/config/basicServices';
import DISCOUNTS from '@/config/discounts';
import TOP_SCROLL from '@/mixins/topScroll';

export default Vue.extend({
  name: 'Pricing',
  mixins: [
    TOP_SCROLL,
  ],
  data() {
    return {
      ARTWORK,
      artwork: [],
      ArtworkPriceSelection,
      BASIC_SERVICES,
      DISCOUNTS,
      discounts: [],
      grow: '',
      services: [],
      total: 0,
    };
  },
  computed: {
    ...mapGetters([
      'getArtworkFingers',
      'isLargeMode',
    ]),
  },
  watch: {
    getArtworkFingers() {
      const {artwork, cost} = this.getArtworkFingers;
      try {
        this.artwork.push({...artwork, cost});
        this.total += cost;
      } catch {
        // EMpty catch...
      }
    },
  },
  methods: {
    addArtwork(art) {
      if (!art) return;

      this.grow = art.id;
      setTimeout(() => {
        this.grow = '';
      }, 500);

      this.$store.dispatch('setContent', {
        component: ArtworkPriceSelection,
        props: { artwork: art },
      });
      this.$store.dispatch('openModal');
    },
    addDiscount(discount) {
      if (!discount) return;

      if (this.discounts.some((disc) => disc.id === discount.id)) return;

      this.grow = discount.id;
      setTimeout(() => {
        this.grow = '';
      }, 500);
      this.discounts.push({...discount});
      this.total += discount.cost;

      if (discount.id.indexOf('mani') > -1) {
        // Make sure that if the discount is for mani & pedi, that we select at least one of each...
        if (!this.services.some((service) => service.id.indexOf('mani') > -1 && service.id.indexOf('kid') === -1)) {
          this.addService(this.BASIC_SERVICES.find((service) => service.id.indexOf('mani') > -1));
        }
        if (!this.services.some((service) => service.id.indexOf('pedi') > -1 && service.id.indexOf('kid') === -1)) {
          this.addService(this.BASIC_SERVICES.find((service) => service.id.indexOf('pedi') > -1));
        }
      }
    },
    addService(service) {
      if (!service) return;

      this.grow = service.id;
      setTimeout(() => {
        this.grow = '';
      }, 500);
      this.services.push({...service});
      this.total += service.cost;
      this.checkToApplyDiscount();
    },
    artworkIndex({ id }) {
      return this.artwork.findIndex((art) => art.id === id);
    },
    checkToApplyDiscount() {
      // Check for adult mani/pedi, not kid mani/pedi
      const maniFound = this.services.some((service) => service.id.indexOf('mani') > -1 && service.id.indexOf('kid') === -1);
      const pediFound = this.services.some((service) => service.id.indexOf('pedi') > -1 && service.id.indexOf('kid') === -1);
      const discount = this.DISCOUNTS.find((discount) => discount.id.indexOf('mani') > -1);

      if (maniFound && pediFound) {
        this.addDiscount(discount);
      } else {
        this.removeDiscount(discount);
      }
    },
    discountIndex({ id }) {
      return this.discounts.findIndex((discount) => discount.id === id);
    },
    removeArtwork({ id }) {
      if (!id) return;
      
      const index = this.artworkIndex({id});
      if (index < 0) return;

      const artwork = this.artwork[index];
      
      this.total = this.total - artwork.cost < 0 ? 0 : this.total - artwork.cost;
      this.artwork.splice(index, 1);
    },
    removeDiscount({ cost, id }) {
      if (!id) return;
      
      const index = this.discountIndex({id});
      if (index < 0) return;
      this.discounts.splice(index, 1);
      this.total = this.total - cost < 0 ? 0 : this.total - cost;
    },
    removeService({ cost, id }) {
      if (!id) return;
      
      const index = this.serviceIndex({id});
      if (index < 0) return;
      this.services.splice(index, 1);
      this.total = this.total - cost < 0 ? 0 : this.total - cost;
      this.checkToApplyDiscount();
    },
    serviceIndex({ id }) {
      return this.services.findIndex((service) => service.id === id);
    },
  },
});
</script>

<style lang="scss" scoped>
@keyframes grow {
  from {background: rgb(255, 255, 255);}
  to {opacity: 0;}
}

.pricing-button {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 25px;
  color: black;
  cursor: pointer;
  height: 24px;
  margin: auto;
  opacity: 1;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 24px;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
    color: white;
  }

  i {
    padding-top: 4px;
  }
}

.pricing-empty {
  --width-mobile: 1;
}

.pricing-grow {
  animation-name: grow;
  animation-duration: 0.25s;
  z-index: 900;
}

.larger-box {
  --width-mobile: 7;
}

.pricing-container-large {
  position: sticky;
  top: 60px;
}

.pricing-container-small {
  position: sticky;
  top: 0px;
}

.pricing-header {
  font-family: 'Luxury Royale JF';
  font-size: 76px;
  padding-bottom: 24px;
  padding-top: 24px;
}

.smaller-box {
  --width-mobile: 4;
}

.pricing-table {
  border: 1px solid white;
  border-spacing: 0px;
  margin-bottom: 16px;
  width: 100%;

  .pricing-cart {
    text-align: center !important;
    width: 40px;
  }

  .pricing-cost {
    padding-right: 12px;
    width: 48px;
  }

  .large-item {
    padding-left: 18px;
  }

  .large-value {
    padding-right: 8px;
  }

  .pricing {
    text-align: center;
  }

  .small-item {
    padding-left: 16px;
  }

  .small-value {
    padding-right: 4px;
  }

  .pricing-sub-header {
    padding-left: 8px;
  }

  td {
    padding-bottom: 4px;
    padding-top: 4px;
    text-align: left;
  }

  th {
    border-bottom: 1px solid white;
    font-size: 20px;
    padding-bottom: 8px;
    padding-top: 8px;
    text-align: left;
  }
}

.pricing-table-container {
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 24px;
}

.pricing-table-header {
  font-family: 'Bank Gothic';
  font-size: 18px;
}
</style>

<template>
    <div class="media-group bn-site-color">
        <span class="fa-stack link" @click="openLink('https://www.facebook.com/BeautifulArtisticRealEclecticNails/')">
            <i class="fa fa-circle-thin fa-stack-2x"></i>
            <i class="fa fa-facebook fa-stack-1x"></i>
        </span>
        <span class="fa-stack link" @click="openLink('https://www.twitter.com/bare_nails/')">
            <i class="fa fa-circle-thin fa-stack-2x"></i>
            <i class="fa fa-twitter fa-stack-1x"></i>
        </span>
        <span class="fa-stack link" @click="openLink('https://www.instagram.com/b.a.r.e._nails/')">
            <i class="fa fa-circle-thin fa-stack-2x"></i>
            <i class="fa fa-instagram fa-stack-1x"></i>
        </span>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
    name: 'SocialMediaGroup',
    methods: {
        openLink(link) {
           window.open(link);
       },
    },
});
</script>

<style lang="scss" scoped>
.link {
    cursor: pointer;
    font-size: 1em;
}

.media-group {
    span {
        padding: 0px 4px 0px 4px;
    }
}
</style>

const DISCOUNTS = [
  {
    cost: -5,
    id: 'mani-pedi-combo',
    service: 'Mani & Pedi',
    short: 'M/P',
  },
  {
    cost: -5,
    id: 'verteran',
    service: 'Veteran',
    short: 'Vet',
  },
];

export default DISCOUNTS;

<template>
  <div>
    <div v-if="textLocation === 'top' && text" class="photo-text bn-site-color">
      {{ text }}
    </div>

    <div v-if="setup === PICTURE_SETUP.SIDE_BY_SIDE && legit" class="photo-container">
      <div class="photo-column photo-half">
        <img class="photo-image" :src="images[0]" />
      </div>
      <div class="photo-column photo-empty">&nbsp;</div>
      <div class="photo-column photo-half">
        <img class="photo-image" :src="images[1]" />
      </div>
    </div>

    <div v-else-if="setup === PICTURE_SETUP.TOP_BOTTOM && legit" class="photo-container">
      <div class="photo-column photo-full">
        <img class="photo-image" :src="images[0]" />
      </div>
      <div class="photo-column photo-full">&nbsp;</div>
      <div class="photo-column photo-full">
        <img class="photo-image" :src="images[1]" />
      </div>
    </div>

    <div v-else-if="setup === PICTURE_SETUP.ONE_LEFT_TWO_RIGHT && legit" class="photo-container">
      <div class="photo-column photo-half">
        <img class="photo-image" :src="images[0]" />
      </div>
      <div class="photo-column photo-empty">&nbsp;</div>
      <div class="photo-column photo-half">
        <div class="photo-container">
          <div class="photo-column photo-full">
            <img class="photo-image" :src="images[1]" />
          </div>
          <div class="photo-full">&nbsp;</div>
          <div class="photo-column photo-full">
            <img class="photo-image" :src="images[2]" />
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="setup === PICTURE_SETUP.TWO_LEFT_ONE_RIGHT && legit" class="photo-container">
      <div class="photo-column photo-half">
        <div class="photo-container">
          <div class="photo-column photo-full">
            <img class="photo-image" :src="images[0]" />
          </div>
          <div class="photo-full">&nbsp;</div>
          <div class="photo-column photo-full">
            <img class="photo-image" :src="images[1]" />
          </div>
        </div>
      </div>
      <div class="photo-column photo-empty">&nbsp;</div>
      <div class="photo-column photo-half">
        <img class="photo-image" :src="images[2]" />
      </div>
    </div>

    <div v-else-if="setup === PICTURE_SETUP.ONE_TOP_TWO_BOTTOM && legit" class="photo-container">
      <div class="photo-column photo-full">
        <img class="photo-image" :src="images[0]" />
      </div>
      <div class="photo-column photo-full">&nbsp;</div>
      <div class="photo-column photo-half">
        <img class="photo-image" :src="images[1]" />
      </div>
      <div class="photo-column photo-empty">&nbsp;</div>
      <div class="photo-column photo-half">
        <img class="photo-image" :src="images[2]" />
      </div>
    </div>

    <div v-else-if="setup === PICTURE_SETUP.TWO_TOP_ONE_BOTTOM && legit" class="photo-container">
      <div class="photo-column photo-half">
        <img class="photo-image" :src="images[0]" />
      </div>
      <div class="photo-column photo-empty">&nbsp;</div>
      <div class="photo-column photo-half">
        <img class="photo-image" :src="images[1]" />
      </div>
      <div class="photo-column photo-full">&nbsp;</div>
      <div class="photo-column photo-full">
        <img class="photo-image" :src="images[2]" />
      </div>
    </div>

    <div v-else-if="setup === PICTURE_SETUP.THREE_SINGLES && legit" class="photo-container">
      <div class="photo-column photo-third">
        <img class="photo-image" :src="images[0]" />
      </div>
      <div class="photo-column photo-empty">&nbsp;</div>
      <div class="photo-column photo-third">
        <img class="photo-image" :src="images[1]" />
      </div>
      <div class="photo-column photo-empty">&nbsp;</div>
      <div class="photo-column photo-third">
        <img class="photo-image" :src="images[2]" />
      </div>
    </div>

    <div v-else-if="setup === PICTURE_SETUP.THREE_TOP_TO_BOTTOM && legit" class="photo-container">
      <div class="photo-column photo-full">
        <img class="photo-image" :src="images[0]" />
      </div>
      <div class="photo-column photo-full">&nbsp;</div>
      <div class="photo-column photo-full">
        <img class="photo-image" :src="images[1]" />
      </div>
      <div class="photo-column photo-full">&nbsp;</div>
      <div class="photo-column photo-full">
        <img class="photo-image" :src="images[2]" />
      </div>
    </div>

    <div v-else-if="setup === PICTURE_SETUP.FOUR_BOXES && legit" class="photo-container">
      <div class="photo-column photo-half">
        <img class="photo-image" :src="images[0]" />
      </div>
      <div class="photo-column photo-empty">&nbsp;</div>
      <div class="photo-column photo-half">
        <img class="photo-image" :src="images[1]" />
      </div>
      <div class="photo-column photo-full">&nbsp;</div>
      <div class="photo-column photo-half">
        <img class="photo-image" :src="images[2]" />
      </div>
      <div class="photo-column photo-empty">&nbsp;</div>
      <div class="photo-column photo-half">
        <img class="photo-image" :src="images[3]" />
      </div>
    </div>

    <div v-else class="photo-container">
      <img class="photo-image" :src="images[0]" />
    </div>

    <div v-if="textLocation !== 'top' && text" class="photo-text bn-site-color">
      {{ text }}
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';

import PICTURE_SETUP from '@/enums/PictureSetup';

export default Vue.extend({
  name: 'PhotoCard',
  props: {
    images: {
      type: Array,
      required: true,
    },
    setup: {
      type: Number,
      default: PICTURE_SETUP.DEFAULT,
    },
    text: {
      type: String,
      default: '',
    },
    textLocation: {
      type: String, // bottom, top
      default: 'bottom',
    },
  },
  data() {
    return {
      PICTURE_SETUP,
    };
  },
  computed: {
    legit() {
      switch (this.setup) {
        case PICTURE_SETUP.DEFAULT:
        case PICTURE_SETUP.ONE:
          return this.images.length > 0;
        case PICTURE_SETUP.SIDE_BY_SIDE: return this.images.length > 1;
        case PICTURE_SETUP.TOP_BOTTOM: return this.images.length > 1;
        case PICTURE_SETUP.ONE_LEFT_TWO_RIGHT: return this.images.length > 2;
        case PICTURE_SETUP.TWO_LEFT_ONE_RIGHT: return this.images.length > 2;
        case PICTURE_SETUP.ONE_TOP_TWO_BOTTOM: return this.images.length > 2;
        case PICTURE_SETUP.TWO_TOP_ONE_BOTTOM: return this.images.length > 2;
        case PICTURE_SETUP.THREE_SINGLES: return this.images.length > 2;
        case PICTURE_SETUP.THREE_TOP_TO_BOTTOM: return this.images.length > 2;
        case PICTURE_SETUP.FOUR_BOXES: return this.images.length > 3;
      }
      return false;
    },
  },
});
</script>

<style lang="scss" scoped>
.photo-column {
  --columns: 95;
  --width: var(--width-mobile, 0);

  flex-basis: calc(var(--width) / var(--columns) * 100%);
}

.photo-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 100%;
}

.photo-empty {
  --width-mobile: 1;
}

.photo-full {
  --width-mobile: 95;
}

.photo-half {
  --width-mobile: 47;
}

.photo-image {
  width: 100%;
}

.photo-table {
  border-spacing: 0px;
}

.photo-text {
  font-family: Arial, Helvetica, sans-serif;
  padding: 4px 0px 4px 0px;
}

.photo-third {
  --width-mobile: 31;
}
</style>

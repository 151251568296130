import { Auth } from 'aws-amplify';

const ADMIN_ACCESS = {
  data() {
    return {
      isLoggedIn: false,
    };
  },
  beforeCreate() {
    const openLoginModal = () => {
      this.$store.dispatch('openLoginModal');
    };

    // Make sure the user is logged in and is part of the Admins group
    Auth.currentSession()
      .then((res) => {
        if (!res.isValid()) {
          openLoginModal();
        } else if (!res.getIdToken().payload['cognito:groups'].includes('admins')) {
          openLoginModal();
        } else {
          this.isLoggedIn = true;
        }
      })
      .catch(() => openLoginModal());
  },
};

export default ADMIN_ACCESS;

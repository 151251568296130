const ClientData = {
  getters: {
    
  },
  actions: {
    addNewClient(context, client) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverAddNewClient', client)
            .then((client) => resolve(client));
        } catch {
          reject(null);
        }
      });
    },
    deleteClient(context, id) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverDeleteClient', id)
            .then((isSuccess) => resolve(isSuccess));
        } catch {
          reject(false);
        }
      });
    },
    getAllClients(context) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverGetAllClients')
            .then((clients) => resolve(clients));
        } catch {
          reject([]);
        }
      });
    },
    getClient(context, id) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverGetClient', id)
            .then((client) => resolve(client));
        } catch {
          reject(null);
        }
      });
    },
    updateClient(context, updatedClient) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverUpdateClient', updatedClient)
            .then((client) => resolve(client));
        } catch {
          reject(null);
        }
      });
    },
  },
  mutations: {

  },
  state: {
    
  },
};

export default ClientData;

<template>
  <div class="bn-container bn-site-color">
    <b-loading v-model="isLoading" />
    <div class="bn-column bn-card bn-site-background-color">
      <div class="header bn-luxury">Appointment</div>
      <div v-if="true" class="sub-header">
        We are currently relocating the business to northern West Virginia and look forward to serving the people in that area soon!<br><br>
        Thank you to all of our lovely clients in the southeast Aurora, CO area for all of their business. You will be missed!
      </div>
      <div v-else>
        <div class="sub-header">Book an appointment online</div>
        <div class="sub-header">** For same day appointments, please text or call (303) 489-2709 **</div>
        <div class="sub-header">
          *** Due to issues with other salon's standards, I will not work on products from other salons with the exception being regular nail polish ***
        </div>
        <div class="columns column is-full">
          <div class="column is-4 min-width">
            <b-field class="label"
              label="First Name"
              :type="{'is-danger': !firstNameValid}"
              :message="{'Please enter your first name.': !firstNameValid}">
              <b-input maxlength="30" v-model="appointment.firstName" @blur="checkFirstName" required />
            </b-field>
          </div>
          <div class="column is-4 min-width">
            <b-field class="label"
              label="Last Name"
              :type="{'is-danger': !lastNameValid}"
              :message="{'Please enter your last name.': !lastNameValid}">
              <b-input maxlength="30" v-model="appointment.lastName" @blur="checkLastName" required />
            </b-field>
          </div>
          <div class="column is-4 min-width">
            <b-field
              class="label"
              label="Phone Number"
              :type="{'is-danger': !phoneValid || !phoneNumberValid}"
              :message="phoneErrorList">
              <b-input placeholder="123-456-7890" maxlength="12" v-model="phoneNumber" @input="phoneNumberChange" required />
            </b-field>
          </div>
        </div>
        <div class="columns column is-full">
          <div class="column is-4 min-width date-container">
            <b-field
              class="label"
              label="Requested Date/Time"
              :type="{'is-danger': !dateIsValid}"
              :message="{'Please enter a date and time.': !dateIsValid}">
              <b-datetimepicker
                class="date-time-picker"
                icon="calendar-today"
                placeholder="Click to select..."
                position="is-top-right"
                :min-datetime="new Date()"
                v-model="appointment.requestedFor"
                @blur="checkDateIsValid" />
            </b-field>
          </div>
          <div class="column is-4 min-width select-parent-container">
            <b-tooltip
              class="candle-icon"
              size="is-small"
              type="is-light"
              :label="candleTooltip"
              multilined>
              <i class="fa fa-question-circle" />
            </b-tooltip>
            <b-field
              class="label select-container"
              label="Candle (No Charge)"
              :type="{'is-danger': !candleValid}"
              :message="{'Please select a candle.': !candleValid}">
              <b-select v-model="appointment.candle" @blur="checkCandle" required>
                <option v-for="candle in CANDLES" :key="candle" :value="candle">{{ candle }}</option>
              </b-select>
            </b-field>
          </div>
          <div class="column is-4 min-width select-parent-container">
            <b-tooltip class="music-icon" :label="musicTooltip" type="is-light" size="is-small" multilined>
              <i class="fa fa-question-circle" />
            </b-tooltip>
            <b-field class="label select-container" label="Music (No Charge)">
              <b-input maxlength="30" placeholder="Spa Music" v-model="appointment.music" />
            </b-field>
          </div>
        </div>
        <div class="columns column is-full">
          <div class="column is-4 min-width select-parent-container">
            <b-tooltip
              class="service-icon"
              position="is-right"
              size="is-small"
              type="is-light"
              :label="servicesTooltip"
              multilined>
              <i class="fa fa-question-circle" />
            </b-tooltip>
            <b-field
              class="label"
              label="Services"
              :type="{'is-danger': !servicesValid}"
              :message="{'Please select at least one service.': !servicesValid}">
              <b-select class="select-size" v-model="appointment.services" @blur="checkServices" multiple required>
                <option v-for="service in BASIC_SERVICES"
                  :key="service.id"
                  :value="service.id">
                  {{ service.service }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column is-8 min-width">
            <b-field class="label" label="Comments">
              <b-input
                type="textarea"
                class="text-area"
                placeholder="Enter any additional comments..."
                maxlength="600"
                v-model="appointment.comments" />
            </b-field>
          </div>
        </div>
        <div class="columns column is-full">
          <div class="column is-8">&nbsp;</div>
          <div class="column is-4">
            <b-button type="is-light" class="clear-button" @click="clear" inverted>Clear</b-button>
            <b-button type="is-dark" @click="request" inverted>Request</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';

import { Appointment, AppointmentStatus } from '@/models/index';

import { failure, success } from '@/utility/messages';
import BASIC_SERVICES from '@/config/basicServices';
import CANDLES from '@/config/candles';
import TOP_SCROLL from '@/mixins/topScroll';

export default Vue.extend({
  name: 'Appointment',
  mixins: [
    TOP_SCROLL,
  ],
  data() {
    return {
      appointment: { services: [], },
      BASIC_SERVICES,
      CANDLES,
      candleValid: true,
      dateIsValid: true,
      firstNameValid: true,
      isLoading: false,
      lastNameValid: true,
      phoneNumberValid: true,
      phoneValid: true,
      phoneNumber: '',
      servicesValid: true,
    };
  },
  computed: {
    candleTooltip() {
      return 'Enjoy a lit scented candle during your service!';
    },
    id() {
      return this.$route.params.id;
    },
    musicTooltip() {
      return 'Enjoy music of your choice during your service!';
    },
    phoneErrorList() {
      return [
        { 'Phone number is invalid.': !this.phoneNumberValid },
        { 'Please add a phone number.': !this.phoneValid },
      ];
    },
    servicesTooltip() {
      return 'Select services you would like. Ctrl+click to select more than one.';
    },
  },
  methods: {
    checkCandle() {
      this.candleValid = this.appointment.candle ? true : false;
      return this.candleValid;
    },
    checkDateIsValid() {
      this.dateIsValid = this.appointment.requestedFor;
      return this.dateIsValid;
    },
    checkFirstName() {
      this.firstNameValid = this.appointment.firstName ? true : false;
      return this.firstNameValid;
    },
    checkLastName() {
      this.lastNameValid = this.appointment.lastName ? true : false;
      return this.lastNameValid;
    },
    checkServices() {
      this.servicesValid = this.appointment.services.length > 0;
      return this.servicesValid;
    },
    clear() {
      this.appointment = { services: [], };
      this.candleValid = true;
      this.dateIsValid = true;
      this.firstNameValid = true;
      this.lastNameValid = true;
      this.phoneNumberValid = true;
      this.phoneValid = true;
      this.phoneNumber = '';
      this.servicesValid = true;
    },
    phoneNumberChange(val) {
      let newVal = val;
      
      if (isNaN(val.replace(/-/g, '')) || (val.length !== 4 && val.length !== 8 && val[val.length-1] === '-')) {
        newVal = val.substr(0, val.length - 2);
      }

      if (val.length > 7 && val[7] !== '-') {
        newVal = `${val.substr(0, 7)}-${val.substr(7)}`;
      } else if (val.length > 3 && val.indexOf('-') === -1) {
        newVal = `${val.substr(0, 3)}-${val.substr(3)}`;
      }
      this.phoneNumber = newVal;

      if (this.phoneNumber.length !== 12) {
        this.phoneNumberValid = false
      } else {
        this.phoneNumberValid = true;
      }
      if (!this.phoneNumber) {
        this.phoneValid = false;
      } else {
        this.phoneValid = true;
      }

      return this.phoneNumberValid && this.phoneValid;
    },
    request() {
      let valid = this.checkCandle();
      valid = this.checkDateIsValid() && valid;
      valid = this.checkFirstName() && valid;
      valid = this.checkLastName() && valid;
      valid = this.checkServices() && valid;
      valid = this.phoneNumberChange(this.phoneNumber) && valid;

      if (!valid) return;

      this.isLoading = true;
      const FAILED_MSG = `There was an issue requesting your appointment.
        Please try again later or call (303) 489-2709 to request an appointment directly.`;

      const now = new Date().getTime();
      const comments = this.appointment.comments ? this.appointment.comments : 'None';
      const music = this.appointment.music ? this.appointment.music : 'Spa Music';
      const newAppt = new Appointment({
        firstName: this.appointment.firstName,
        lastName: this.appointment.lastName,
        phoneNumber: this.phoneNumber,
        createdOn: now,
        editedOn: now,
        requestedFor: this.appointment.requestedFor.getTime(),
        services: this.appointment.services,
        candle: this.appointment.candle,
        music,
        comments,
        status: AppointmentStatus.REQUEST,
      });

      this.$store.dispatch('createAppointment', newAppt)
        .then((app) => {
          if (app) {
            this.clear();
            this.$router.push('/');
            success(this, {duration: 20000, message: `B.A.R.E. Nails will contact you shortly to confirm the appointment.
              The appointment is not valid until confirmed. Thank you!`});
          } else {
            failure(this, {duration: 30000, message: FAILED_MSG});
          }
        })
        .catch(() => failure(this, {duration: 30000, message: FAILED_MSG}))
        .finally(() => this.isLoading = false);
    },
  },
});
</script>

<style lang="scss" scoped>
.candle-icon {
  color: white;
  cursor: pointer;
  left: 168px;
  position: absolute;
  top: 12px;
}

.clear-button {
  background-color: rgba(255, 255, 255, 0.5) !important;
  margin-right: 12px;
}

.date-time-picker {
  width: 100%;
}

.header {
  font-size: 72px;
  padding-bottom: 24px;
  padding-top: 30px;
}

.label {  
  text-align: left;
}

.label::v-deep label {
  color: white;
}

.min-width {
 min-width: 200px;
}

.music-icon {
  color: white;
  cursor: pointer;
  left: 160px;
  position: absolute;
  top: 12px;
}

.select-container::v-deep span, .select-container::v-deep select {
  width: 100%;
}

.select-parent-container {
  position: relative;
}

.select-size::v-deep span, .select-size::v-deep select {
  width: 100%;
}

.service-container {
  display: relative;
}

.service-icon {
  color: white;
  cursor: pointer;
  left: 82px;
  position: absolute;
  top: 12px;
}

.sub-header {
  padding-bottom: 24px;
}

.text-area::v-deep textarea {
  height: 154px;
}
</style>

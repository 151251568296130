/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPayment = /* GraphQL */ `
  query GetPayment($id: ID!) {
    getPayment(id: $id) {
      id
      paymentDate
      percentage
      paid
      paidDate
      month
      year
      createdOn
      editedOn
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listPayments = /* GraphQL */ `
  query ListPayments(
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        paymentDate
        percentage
        paid
        paidDate
        month
        year
        createdOn
        editedOn
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getGiftCard = /* GraphQL */ `
  query GetGiftCard($id: ID!) {
    getGiftCard(id: $id) {
      id
      initialTotal
      tip
      remaining
      service
      remainingServices
      year
      day
      month
      cardId
      createdOn
      editedOn
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listGiftCards = /* GraphQL */ `
  query ListGiftCards(
    $filter: ModelGiftCardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGiftCards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        initialTotal
        tip
        remaining
        service
        remainingServices
        year
        day
        month
        cardId
        createdOn
        editedOn
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getExpense = /* GraphQL */ `
  query GetExpense($id: ID!) {
    getExpense(id: $id) {
      id
      expense
      cost
      month
      year
      profitId
      profit {
        id
        service
        clientId
        client {
          id
          firstName
          lastName
          phoneNumber
          comments
          createdAt
          updatedAt
          owner
        }
        serviceDate
        tips
        cost
        month
        year
        day
        createdOn
        editedOn
        createdAt
        updatedAt
        owner
      }
      createdOn
      editedOn
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listExpenses = /* GraphQL */ `
  query ListExpenses(
    $filter: ModelExpenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExpenses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        expense
        cost
        month
        year
        profitId
        profit {
          id
          service
          clientId
          serviceDate
          tips
          cost
          month
          year
          day
          createdOn
          editedOn
          createdAt
          updatedAt
          owner
        }
        createdOn
        editedOn
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getProfit = /* GraphQL */ `
  query GetProfit($id: ID!) {
    getProfit(id: $id) {
      id
      service
      clientId
      client {
        id
        firstName
        lastName
        phoneNumber
        comments
        createdAt
        updatedAt
        owner
      }
      serviceDate
      tips
      cost
      month
      year
      day
      createdOn
      editedOn
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listProfits = /* GraphQL */ `
  query ListProfits(
    $filter: ModelProfitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfits(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        service
        clientId
        client {
          id
          firstName
          lastName
          phoneNumber
          comments
          createdAt
          updatedAt
          owner
        }
        serviceDate
        tips
        cost
        month
        year
        day
        createdOn
        editedOn
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getClient = /* GraphQL */ `
  query GetClient($id: ID!) {
    getClient(id: $id) {
      id
      firstName
      lastName
      phoneNumber
      comments
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listClients = /* GraphQL */ `
  query ListClients(
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        phoneNumber
        comments
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const paymentByDate = /* GraphQL */ `
  query PaymentByDate(
    $year: Int
    $monthPaymentDate: ModelPaymentPaymentByDateCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    paymentByDate(
      year: $year
      monthPaymentDate: $monthPaymentDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        paymentDate
        percentage
        paid
        paidDate
        month
        year
        createdOn
        editedOn
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const expenseByDate = /* GraphQL */ `
  query ExpenseByDate(
    $year: Int
    $monthCreatedOn: ModelExpenseExpenseByDateCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelExpenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    expenseByDate(
      year: $year
      monthCreatedOn: $monthCreatedOn
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        expense
        cost
        month
        year
        profitId
        profit {
          id
          service
          clientId
          serviceDate
          tips
          cost
          month
          year
          day
          createdOn
          editedOn
          createdAt
          updatedAt
          owner
        }
        createdOn
        editedOn
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const profitByDate = /* GraphQL */ `
  query ProfitByDate(
    $year: Int
    $monthDay: ModelProfitProfitByDateCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProfitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    profitByDate(
      year: $year
      monthDay: $monthDay
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        service
        clientId
        client {
          id
          firstName
          lastName
          phoneNumber
          comments
          createdAt
          updatedAt
          owner
        }
        serviceDate
        tips
        cost
        month
        year
        day
        createdOn
        editedOn
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getAppointment = /* GraphQL */ `
  query GetAppointment($id: ID!) {
    getAppointment(id: $id) {
      id
      firstName
      lastName
      phoneNumber
      candle
      music
      services
      comments
      requestedFor
      createdOn
      editedOn
      status
      Client {
        id
        firstName
        lastName
        phoneNumber
        comments
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listAppointments = /* GraphQL */ `
  query ListAppointments(
    $filter: ModelAppointmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAppointments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        phoneNumber
        candle
        music
        services
        comments
        requestedFor
        createdOn
        editedOn
        status
        Client {
          id
          firstName
          lastName
          phoneNumber
          comments
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;

<template>
  <div v-if="isLoggedIn" class="parent bn-container">
    <div class="bn-column bn-card bn-site-background-color bn-site-color">
      <div class="bn-luxury admin-title">Admin</div>
      <div class="bn-container">
        <div class="bn-column links-container">  
          <span @click="openLink('https://us-east-2.console.aws.amazon.com/amplify/home?region=us-east-2#/')">
            AWS
          </span>
          <span @click="reroute('/admin/profitloss')">
            Profits/Losses
          </span>
          <span @click="reroute('/admin/clients')">
            Clients
          </span>
          <span @click="reroute('/admin/payments')">
            Payments
          </span>
          <span @click="reroute('/admin/giftcards')">
            Gift Cards
          </span>
        </div>
        <div class="bn-column appointment-container">
          <div class="admin-sub-title">Appointments</div>
          <b-table
            class="appts-table"
            :loading="isLoading"
            :data="appointments"
            :striped="true"
            :narrowed="true"
            :hoverable="true"
            :selected.sync="selected"
            :mobile-cards="true">
            <b-table-column label="First" v-slot="props">
              {{ props.row.firstName }}
            </b-table-column>
            <b-table-column label="Last" v-slot="props">
              {{ props.row.lastName }}
            </b-table-column>
            <b-table-column label="Phone" v-slot="props">
              {{ props.row.phoneNumber }}
            </b-table-column>
            <b-table-column label="For" v-slot="props">
              {{ props.row.requestedFor | toDate }}
            </b-table-column>
            <b-table-column label="Status" v-slot="props">
              {{ props.row.status }}
            </b-table-column>
          </b-table>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <br><br><br><br><br><br><br><br><br><br><br><br>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';

import { failure } from '@/utility/messages';
import ADMIN_ACCESS from '@/mixins/adminAccess';

export default Vue.extend({
  name: 'Admin',
  mixins: [
    ADMIN_ACCESS,
  ],
  data() {
    return {
      appointments: [],
      isLoading: false,
      selected: null,
    };
  },
  computed: {
    listedAppointments() {
      return this.appointments;
    },
  },
  watch: {
    selected(val) {
      this.selectAppt(val.id);
    },
  },
  created() {
    this.getAppointments();
  },
  methods: {
    formatDate(ms) {
      if (!ms || isNaN(Number(ms))) return 'Invalid Date';

      const date = new Date(Number(ms));
      const m = date.getMonth() + 1;
      const d = date.getDate();
      const y = date.getFullYear();
      const h = date.getHours() + 1;
      const min = date.getMinutes() < 10 ? String(`0${date.getMinutes()}`) : date.getMinutes();

      return `${m}/${d}/${y} ${h}:${min}`;
    },
    getAppointments() {
      this.isLoading = true;

      this.$store.dispatch('getAppointments')
        .then((appts) => this.appointments = appts)
        .catch(() => failure(this, {message: 'Error retrieving appointments.'}))
        .finally(() => this.isLoading = false);
    },
    openLink(link) {
      window.open(link);
    },
    reroute(route) {
      this.$router.push(route);
    },
    selectAppt(id) {
      if (!id) return;

      this.$router.push(`/admin/appointment/${id}`);
    },
  },
});
</script>

<style lang="scss" scoped>
.admin-title {
  font-size: 72px;
  padding-bottom: 24px;
}

.admin-sub-title {
  border-bottom: 1px solid white;
  color: white;
  font-family: 'Bank Gothic';
  font-size: 24px;
}

.appts-table {
  cursor: pointer;
  text-align: left;
  width: 100%;
}

.appointment-container {
  margin: 0px 12px;
  padding-top: 24px;
}

.appointment-header {
  font-size: 18px;
}

.links-container {
  width: 100%;

  span {
    cursor: pointer;
    text-decoration: underline;
  }

  span:not(:first-child) {
    padding-left: 12px;
  }
}

.links-header {
  font-size: 24px;
  text-decoration: underline;
}

.link-text {
  cursor: pointer;
  padding-left: 8px;
  text-align: left;
}

.parent {
  padding-top: 30px;
}
</style>

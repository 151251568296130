const ARTWORK = [
  {
    cost: 0,
    costs: [
      { count: 1, cost: 0 },
      { count: 2, cost: 0 },
      { count: 3, cost: 9 },
      { count: 4, cost: 9 },
      { count: 5, cost: 12 },
      { count: 6, cost: 12 },
      { count: 7, cost: 16 },
      { count: 8, cost: 16 },
      { count: 9, cost: 20 },
      { count: 10, cost: 20 },
    ],
    icon: 'fa fa-question-circle',
    id: 'simple',
    images: [
      require('../../assets/examples/asian.jpg'),
      require('../../assets/examples/marble_scarce.jpg'),
      require('../../assets/examples/vampire.jpg'),
    ],
    service: 'Simple',
    short: 'Art (S)',
    text: 'Simple artwork prices vary, add to see options',
  },
  {
    cost: 0,
    costs: [
      { count: 1, cost: 8 },
      { count: 2, cost: 8 },
      { count: 3, cost: 12 },
      { count: 4, cost: 12 },
      { count: 5, cost: 20 },
      { count: 6, cost: 20 },
      { count: 7, cost: 35 },
      { count: 8, cost: 35 },
      { count: 9, cost: 50 },
      { count: 10, cost: 50 },
    ],
    icon: 'fa fa-question-circle',
    id: 'medium',
    images: [
      require('../../assets/examples/feather.jpg'),
      require('../../assets/examples/orange_pumpkins.jpg'),
      require('../../assets/examples/palms.jpg'),
    ],
    service: 'Medium',
    short: 'Art (M)',
    text: 'Medium artwork prices vary, add to see options',
  },
  {
    cost: 0,
    costs: [
      { count: 1, cost: 10 },
      { count: 2, cost: 10 },
      { count: 3, cost: 20 },
      { count: 4, cost: 20 },
      { count: 5, cost: 40 },
      { count: 6, cost: 40 },
      { count: 7, cost: 60 },
      { count: 8, cost: 60 },
      { count: 9, cost: 80 },
      { count: 10, cost: 80 },
    ],
    icon: 'fa fa-question-circle',
    id: 'expert',
    images: [
      require('../../assets/examples/chiefs.jpg'),
      require('../../assets/examples/egyptian.jpg'),
      require('../../assets/examples/joy.jpg'),
    ],
    service: 'Expert',
    short: 'Art (E)',
    text: 'Expert artwork prices vary, add to see options',
  },
];

export default ARTWORK;

<template>
    <div class="menu-container" :class="{'menu-fixed': hasFixed, 'popup': isModalActive}">
        <router-link class="menu-home-link" to="/">
            <site-title />
        </router-link>
        <span class="menu-links">
            <template v-for="link in LINKS">
                <router-link v-if="link.inMenu" :key="link.title" :to="link.link">{{ link.title }}</router-link>
            </template>
        </span>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters } from 'vuex';

import LINKS from '@/config/links';
import SiteTitle from '@/components/SiteTitle.vue';

export default Vue.extend({
    name: 'MenuBar',
    components: {
        SiteTitle,
    },
    data() {
        return {
            hasFixed: false,
            LINKS,
        };
    },
    computed: {
        ...mapGetters([
            'isModalActive',
        ]),
    },
    created() {
        const scrollPos = 90;

        if (window.scrollY > scrollPos) {
            this.hasFixed = true;
        }

        window.onscroll = () => {
            this.hasFixed = window.scrollY > scrollPos;
        };
    },
});
</script>

<style scoped>
.menu-container {
    background-image: linear-gradient(-135deg, black, white);
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    display: none !important;
    padding: 8px 8px 8px 8px;
    width: 100%;
    z-index: 1000;
}

.menu-fixed {
    display: inherit !important;
    position: fixed;
    top: 0px;
}

.menu-home-link {
    color: black;
    text-decoration: none;
}

.menu-links {
    font-family: Arial, Helvetica, sans-serif;
    margin-bottom: auto;
    margin-left: 20px;
    margin-top: auto;
}

.menu-links {
    margin-right: 20px;
}

.menu-links a {
    color: black;
    font-weight: bold;
    margin-right: 20px;
    text-decoration: none;
}

.menu-links a.router-link-exact-active {
    color: black;
}

.popup {
    z-index: 0 !important;
}
</style>

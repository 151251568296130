<template>
  <b-modal
    v-model="isActive"
    has-modal-card
    trap-focus
    :destroy-on-hide="false"
    aria-role="dialog"
    aria-label="Client"
    aria-modal
    @close="close"
  >
    <template>
      <b-loading v-model="isLoading" />
      <form action="">
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">{{ title }}</p>
            <button type="button" class="delete" @click="close" />
          </header>
          <section class="modal-card-body">
            <b-field class="label"
              label="First Name"
              :type="{'is-danger': !firstNameValid}"
              :message="{'Need a first name.': !firstNameValid}">
              <b-input
                maxlength="30"
                v-model="model.firstName"
                required />
            </b-field>
            <b-field class="label"
              label="Last Name"
              :type="{'is-danger': !lastNameValid}"
              :message="{'Need a last name.': !lastNameValid}">
              <b-input
                maxlength="30"
                v-model="model.lastName"
                required />
            </b-field>
            <b-field class="label"
              label="Phone Number"
              :type="{'is-danger': !phoneNumberValid}"
              :message="{'Need a valid number (123-456-7890).': !phoneNumberValid}">
              <b-input
                placeholder="123-456-7890"
                v-model="model.phoneNumber"
                required />
            </b-field>
            <b-field class="label" label="Comments">
              <b-input
                type="textarea"
                class="text-area"
                maxlength="600"
                v-model="model.comments" />
            </b-field>
          </section>
          <footer class="modal-card-foot">
            <b-button v-if="!clientId" class="add-button" @click="addNewClient">Add</b-button>
            <div v-else class="client-buttons">
              <b-button type="is-danger" @click="deleteClient">DELETE</b-button>
              <b-button type="is-dark" @click="update" inverted>Update</b-button>
            </div>
          </footer>
        </div>
      </form>
    </template>
  </b-modal>
</template>

<script lang="ts">
import Vue from 'vue';

import { failure, success } from '@/utility/messages';
import { modelData, fn, ln, pn, isValid } from '@/mixins/checkers';

export default Vue.extend({
  name: 'ClientData',
  mixins: [
    modelData,
  ],
  props: {
    clientId: {
      type: String,
      required: false,
    },
    isModalActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isActive: false,
      isLoading: false,
    };
  },
  computed: {
    isAllValid() {
      return isValid(this.firstNameValid, this.lastNameValid, this.phoneNumberValid);
    },
    title() {
      return this.clientId ? 'Edit Client' : 'Add New Client';
    },
    ...fn,
    ...ln,
    ...pn,
  },
  watch: {
    clientId(newVal) {
      if (newVal) this.getClient(newVal);
    },
    isModalActive(newVal) {
      this.isActive = newVal;
    },
  },
  created() {
    if (this.clientId) {
      this.getClient(this.clientId);
    }
  },
  methods: {
    addNewClient() {
      if (!this.isAllValid) return;

      this.isLoading = true;

      this.$store.dispatch('addNewClient', this.model)
        .then((client) => {
          if (client) {
            this.$store.dispatch('hotReload');
            success(this, {message: 'Client created.'});
            this.close();
          } else {
            failure(this, {message: 'Error creating client.'});
          }
        })
        .catch(() => failure(this, {message: 'Error creating client.'}))
        .finally(() => this.isLoading = false);
    },
    close() {
      this.model = {};
      this.$emit('close', true);
    },
    deleteClient() {
      this.isLoading = true;
      
      this.$store.dispatch('deleteClient', this.model.id)
        .then((isSuccess) => {
          if (isSuccess) {
            this.$store.dispatch('hotReload');
            success(this, {message: 'Client deleted.'});
            this.close();
          } else {
            failure(this, {message: 'Error deleting the client.'});
          }
        })
        .catch(() => failure(this, {message: 'Error deleting the client.'}))
        .finally(() => this.isLoading = false);
    },
    getClient(id) {
      if (!id) return;

      this.isLoading = true;

      this.$store.dispatch('getClient', id)
        .then((client) => {
          if (client) this.model = {...client};
          else failure(this, {message: 'Error loading client.'});
        })
        .catch(() => failure(this, {message: 'Error loading client.'}))
        .finally(() => this.isLoading = false);
    },
    update() {
      if (!this.isAllValid) return;

      this.isLoading = true;

      const updatedClient = {
        id: this.model.id,
        firstName: this.model.firstName,
        lastName: this.model.lastName,
        phoneNumber: this.model.phoneNumber,
        comments: this.model.comments,
      };

      this.$store.dispatch('updateClient', updatedClient)
        .then((client) => {
          if (client) {
            this.$store.dispatch('hotReload');
            success(this, {message: 'Client updated.'});
            this.close();
          } else {
            failure(this, {message: 'Error updating the client.'});
          }
        })
        .catch(() => failure(this, {message: 'Error updating the client.'}))
        .finally(() => this.isLoading = false);
    },
  },
});
</script>

<style lang="scss" scoped>
.add-button {
  float: right;
}

.client-buttons {
  float: right;

  button:last-child {
    margin-left: 12px;
  }
}

.client-header {
  font-size: 24px;
}

.label {
  text-align: left;
}
</style>

const PaymentsData = {
  getters: {
    
  },
  actions: {
    createPayment(context, { day, month, year, percentage }) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverCreatePayment', {day, month, year, percentage})
            .then((payment) => resolve(payment));
        } catch {
          reject(null);
        }
      });
    },
    getPayment(context, id) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverGetPayment', id)
            .then((payment) => resolve(payment));
        } catch {
          reject(null);
        }
      });
    },
    getPayments(context, dateQuery) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverGetPayments', dateQuery)
            .then((payments) => resolve(payments));
        } catch {
          reject([]);
        }
      });
    },
    updatePayment(context, payment) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverUpdatePayment', payment)
            .then((updatedPayment) => resolve(updatedPayment));
        } catch {
          reject(null);
        }
      });
    },
  },
  mutations: {
    
  },
  state: {
    
  },
};

export default PaymentsData;

import { checkValue } from '@/utility/index';

const modelData = {
  data() {
    return {
      model: {},
    };
  },
};

const client = {
  clientValid() {
    return checkValue({value: this.model.client, type: 'object'});
  },
};

const cost = {
  costValid() {
    return checkValue({value: this.model.cost, type: 'number'});
  },
};

const expense = {
  expenseValid() {
    return checkValue({value: this.model.expense, type: 'string'});
  },
};

const fn = {
  firstNameValid() {
    return checkValue({value: this.model.firstName, type: 'string'});
  },
};

const ln = {
  lastNameValid() {
    return checkValue({value: this.model.lastName, type: 'string'});
  },
};

const pn = {
  phoneNumberValid() {
    return checkValue({value: this.model.phoneNumber, type: 'phone'});
  },
};

const service = {
  serviceValid() {
    return checkValue({value: this.model.service, type: 'array'});
  },
};

const sd = {
  serviceDateValid() {
    return checkValue({value: this.model.serviceDate, type: 'number'});
  },
};

const tips = {
  tipsValid() {
    return checkValue({value: this.model.tips, type: 'number'});
  },
};

const isValid = (...args) => {
  return args.reduce((isGood, value) => isGood && value);
};

export { modelData, client, cost, expense, fn, ln, pn, sd, service, tips, isValid, };

const AppointmentData = {
  getters: {

  },
  actions: {
    createAppointment(context, appointment) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverCreateAppointment', appointment)
            .then((app) => resolve(app));
        } catch {
          reject(null);
        }
      });
    },
    deleteAppointment(context, id) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverDeleteAppointment', id)
            .then((isSuccess) => resolve(isSuccess));
        } catch {
          reject(false);
        }
      });
    },
    getAppointment(context, id) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverGetAppointment', id)
            .then((appt) => resolve(appt));
        } catch {
          reject(null);
        }
      });
    },
    getAppointments(context) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverGetAppointments')
            .then((appts) => resolve(appts));
        } catch {
          reject([]);
        }
      });
    },
    updateAppointment(context, updatedAppointment) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverUpdateAppointment', updatedAppointment)
            .then((appt) => resolve(appt));
        } catch {
          reject(null);
        }
      });
    },
  },
  mutations: {

  },
  state: {

  },
};

export default AppointmentData;

<template>
  <div class="bn-container bn-site-color">
    <div class="bn-container about bn-site-background-color" :class="{'bn-sticky': !isLargeMode}">
      <div class="bn-column photo">
        <img class="image" :src="LAUREN" />
      </div>
      <div class="bn-column empty"></div>
      <div class="bn-column photo-text">
        B.A.R.E. Nails was designed with you in mind. We wanted something different for our clients.
        Scented candles, custom music, no rush; we know you are treating yourself, so let us pamper you.
      </div>
      <div class="bn-column empty"></div>
    </div>
    <div class="bn-container about bn-site-background-color" :class="{'bn-sticky': !isLargeMode}">
      <div class="bn-column empty"></div>
      <div class="bn-column photo-text">
        We strongly encourage customized nail art. All nail art is designed for each client; no two art manicures are the same.
        Seasonal art, Christmas, Halloween, even your favorite sports team!
      </div>
      <div class="bn-column empty"></div>
      <div class="bn-column photo">
        <img class="image" :src="CHIEFS" />
      </div>
    </div>
    <div class="bn-container about bn-site-background-color" :class="{'bn-sticky': !isLargeMode}">
      <div class="bn-column photo">
        <img class="image" :src="ASIAN" />
      </div>
      <div class="bn-column empty"></div>
      <div class="bn-column photo-text">
        B.A.R.E. nails is the place to come if you want to stand out at a wedding or be the envy of your co-workers.
      </div>
      <div class="bn-column empty"></div>
    </div>
    <div class="bn-column bn-card text bn-site-background-color" :class="{'bn-sticky': !isLargeMode}">
      B.A.R.E. Nails is up to date with the latest nail trends, offering many styles of French Tips, Ombre, and Themed Manicures.
      All work is customized for the client by Lauren Choy, who has been practicing nail art for 6 years. Grand Opening Specials:
      Basic manicures start at $30 and basic pedicures start at $40. Spa pedicures and men's manicures are available as well.
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters } from 'vuex';

import TOP_SCROLL from '@/mixins/topScroll';

export default Vue.extend({
  name: 'About',
  mixins: [
    TOP_SCROLL,
  ],
  data() {
    return {
      ASIAN: require('../../assets/displays/about/asian.jpg'),
      CHIEFS: require('../../assets/displays/about/chiefs.jpg'),
      LAUREN: require('../../assets/displays/about/lauren.jpg'),
    };
  },
  computed: {
    ...mapGetters([
      'isLargeMode',
    ]),
  },
});
</script>

<style lang="scss" scoped>
.about {
  padding-top: 20px;
}

.empty {
  --width-mobile: 1;
}

.full {
  width: 100%;
}

.image {
  width: 100%;
}

.photo {
  --width-mobile: 4;
}

.photo-text {
  margin: auto;
  --width-mobile: 6;
}

.text {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    line-height: 28px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 16px;
    padding-top: 24px;
}
</style>

<template>
  <div class="bn-container bn-site-color">
    <div class="bn-sticky options bn-site-background-color">
      <span :class="{'selected': selected === traditional}" @click="changePhotos(traditional)">{{ traditional }}</span>
      <span :class="{'selected': selected === themed}"  @click="changePhotos(themed)">{{ themed }}</span>
      <span :class="{'selected': selected === seasonal}"  @click="changePhotos(seasonal)">{{ seasonal }}</span>
    </div>
    <div v-if="selected === traditional" class="bn-container bn-site-background-color photo-container">
      <div v-for="(group, index) in photos.ombre" :key="index"  class="bn-column photos">
        <photo-card :images="group.images" :setup="group.setup" class="photo-card" />
      </div>
    </div>
    <div v-if="selected === themed" class="bn-container bn-site-background-color photo-container">
      <div v-for="(group, index) in photos.themed" :key="index"  class="bn-column photos">
        <photo-card :images="group.images" :setup="group.setup" class="photo-card" />
      </div>
    </div>
    <div v-if="selected === seasonal" class="bn-container bn-site-background-color photo-container">
      <div v-for="(group, index) in photos.seasonal" :key="index"  class="bn-column photos">
        <photo-card :images="group.images" :setup="group.setup" class="photo-card" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';

import PhotoCard from '../components/PhotoCard.vue';
import PHOTO_GROUPS from '@/config/photoGroups';
import PICTURE_SETUP from '@/enums/PictureSetup';
import TOP_SCROLL from '@/mixins/topScroll';

export default Vue.extend({
  name: 'Photos',
  components: {
    PhotoCard,
  },
  mixins: [
    TOP_SCROLL,
  ],
  data() {
    return {
      divCardWidth: window.innerWidth,
      photos: {},
      PICTURE_SETUP,
      selected: '',
    };
  },
  computed: {
    seasonal() {
      return 'Seasonal';
    },
    themed() {
      return 'Themed';
    },
    traditional() {
      return 'Traditional';
    },
  },
  created() {
    this.selected = this.traditional;
  },
  mounted() {
    this.photos = PHOTO_GROUPS;
  },
  methods: {
    changePhotos(option) {
      if (!option) return;

      this.selected = option;
    },
  },
});
</script>

<style lang="scss" scoped>
.options {
  padding-bottom: 8px;
  padding-top: 8px;
  text-decoration: underline;
  width: 100%;
  z-index: 500;

  span {
    cursor: pointer;
    font-family: 'Bank Gothic';
  }

  span:nth-child(odd) {
    padding-left: 12px;
    padding-right: 12px;
  }
}

.photo-card {
  padding-right:4px;
}

.photo-container {
  z-index: 50;
}

.photos {
  --width-mobile: 6;
}

.selected {
  font-family: 'Bank Gothic';
  font-style: italic;
  font-size: 24px;
  text-decoration: underline;
}
</style>

<template>
  <b-modal
    v-model="isActive"
    has-modal-card
    trap-focus
    :destroy-on-hide="true"
    aria-role="dialog"
    aria-label="Expense"
    aria-modal
    @close="close"
  >
    <template>
      <b-loading v-model="isLoading" />
      <form action="">
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">{{ title }}</p>
            <button type="button" class="delete" @click="close" />
          </header>
          <section class="modal-card-body">
            <b-field class="label"
              label="Expense"
              :type="{'is-danger': !expenseValid}"
              :message="{'Need an expense.': !expenseValid}">
              <b-input v-model="model.expense" maxlength="30" expanded required />
            </b-field>
            <b-field class="label" label="Associated Profit">
              <b-select v-model="model.profit" expanded>
                <option v-for="profit in profits" :key="profit.id" :value="profit">
                  {{ `${profit.client.firstName} ${profit.client.lastName}` }}
                </option>
              </b-select>
            </b-field>
            <b-field class="label"
              label="Cost"
              :type="{'is-danger': !costValid}"
              :message="{'Set cost to at least 0.': !costValid}">
              <b-numberinput v-model="model.cost" :min="0" expanded required />
            </b-field>
          </section>
          <footer class="modal-card-foot">
            <b-button v-if="!expenseId" class="add-button" @click="addNewExpense">Add</b-button>
            <div v-else class="expense-buttons">
              <b-button type="is-danger" @click="deleteExpense">DELETE</b-button>
              <b-button type="is-dark" @click="update" inverted>Update</b-button>
            </div>
          </footer>
        </div>
      </form>
    </template>
  </b-modal>
</template>

<script lang="ts">
import Vue from 'vue';

import { failure, success } from '@/utility/messages';
import { modelData, cost, expense, isValid } from '@/mixins/checkers';
import DateQuery from '@/models/dateQuery';

export default Vue.extend({
  name: 'ExpenseData',
  mixins: [
    modelData,
  ],
  props: {
    expenseId: {
      type: String,
      required: false,
    },
    isModalActive: {
      type: Boolean,
      required: true,
    },
    month: {
      type: Number,
      required: true,
    },
    year: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isActive: false,
      isExpenseLoading: false,
      isProfitsLoading: false,
      profits: [],
    };
  },
  computed: {
    isAllValid() {
      return isValid(this.expenseValid, this.costValid);
    },
    isLoading() {
      return this.isProfitsLoading || this.isExpenseLoading;
    },
    title() {
      return this.profitId ? 'Edit Expense' : 'Add New Expense';
    },
    ...cost,
    ...expense,
  },
  watch: {
    expenseId(newVal) {
      if (!newVal) return;
      this.getProfits();
      this.getExpense(newVal);
    },
    isModalActive(newVal) {
      this.isActive = newVal;
    },
  },
  created() {
    this.getProfits();

    if (this.expenseId) {
      this.getExpense(this.expenseId);
    }
  },
  methods: {
    addNewExpense() {
      if (!this.isAllValid) return;

      this.isExpenseLoading = true;

      this.model.month = this.month;
      this.model.year = this.year;

      this.$store.dispatch('addNewExpense', this.model)
        .then((expense) => {
          if (expense) {
            this.$store.dispatch('hotReload');
            success(this, {message: 'Expense created.'});
            this.close();
          } else {
            failure(this, {message: 'Error creating the expense.'});
          }
        })
        .catch(() => failure(this, {message: 'Error creating the expense.'}))
        .finally(() => this.isExpenseLoading = false);
    },
    close() {
      this.model = {};
      this.$emit('close', true);
    },
    deleteExpense() {
      this.isExpenseLoading = true;

      this.$store.dispatch('deleteExpense', this.model.id)
        .then((isSuccess) => {
          if (isSuccess) {
            this.$store.dispatch('hotReload');
            success(this, {message: 'Expense deleted.'});
            this.close();
          } else {
            failure(this, {message: 'Error deleting expense.'});
          }
        })
        .catch(() => failure(this, {message: 'Error deleting expense.'}))
        .finally(() => this.isExpenseLoading = false);
    },
    getExpense(id) {
      if (!id) return;

      this.isExpenseLoading = true;

      this.$store.dispatch('getExpense', id)
        .then((expense) => {
          if (expense) this.model = expense;
          else failure(this, {message: 'Error loading expense.'});
        })
        .catch(() => failure(this, {message: 'Error loading expense.'}))
        .finally(() => this.isExpenseLoading = false);
    },
    getProfits() {
      this.isProfitsLoading = true;
      
      this.$store.dispatch('getProfits', new DateQuery(this.year, this.month))
        .then((profits) => this.profits = profits)
        .catch(() => failure(this, {message: 'Error loading profits.'}))
        .finally(() => this.isProfitsLoading = false);
    },
    update() {
      if (!this.isAllValid) return;

      this.isExpenseLoading = true;

      const updatedExpense = {
        id: this.model.id,
        profitId: this.model.expense ? this.model.expense.id : null,
        cost: this.model.cost,
        month: this.model.month,
        year: this.model.year,
        createdOn: this.model.createdOn,
        editedOn: new Date().getTime(),
      };

      this.$store.dispatch('updateExpense', updatedExpense)
        .then((expense) => {
          if (expense) {
            this.$store.dispatch('hotReload');
            success(this, {message: 'Expense updated.'});
            this.close();
          } else {
            failure(this, {message: 'Error updating expense.'});
          }
        })
        .catch(() => failure(this, {message: 'Error updating expense.'}))
        .finally(() => this.isExpenseLoading = false);
    },
  },
});
</script>

<style lang="scss" scoped>
.add-button {
  float: right;
}

.expense-buttons {
  float: right;

  button:last-child {
    margin-left: 12px;
  }
}

.expense-header {
  font-size: 24px;
}

.label {
  text-align: left;
}
</style>

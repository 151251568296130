const TOP_SCROLL = {
  created() {
    // Scroll to the top of the page
    if (window.scrollY > 0) {
      window.scrollTo(0, 0);
    }
  },
};

export default TOP_SCROLL;

<template>
  <b-modal
    v-model="isActive"
    has-modal-card
    trap-focus
    :destroy-on-hide="false"
    aria-role="dialog"
    aria-label="Payment"
    aria-modal
    @close="close"
  >
    <template>
      <b-loading v-model="isLoading" />
      <form action="">
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">{{ title }}</p>
            <button type="button" class="delete" @click="close" />
          </header>
          <section class="modal-card-body">
            <div class="paid-amount">Paid: ${{ model.paid }}</div>
            <b-field class="paid-date" label="Paid On">
              <b-datepicker
                icon="calendar-today"
                v-model="selectedDate"
                trap-focus />
            </b-field>
          </section>
          <footer class="modal-card-foot">
            <b-button class="paid-button" @click="paid">Mark as Paid</b-button>
          </footer>
        </div>
      </form>
    </template>
  </b-modal>
</template>

<script lang="ts">
import Vue from 'vue';

import { getMonthString } from '@/utility/index';
import { failure, success } from '@/utility/messages';

export default Vue.extend({
  name: 'AcceptPayment',
  props: {
    paymentId: {
      type: String,
      required: true,
    },
    isModalActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isActive: false,
      isLoading: false,
      model: {},
      selectedDate: null,
    };
  },
  computed: {
    title() {
      return `${getMonthString(this.model.month, true)} ${this.model.paymentDate} ${this.model.year}`;
    },
  },
  watch: {
    isModalActive(newVal) {
      this.isActive = newVal;
    },
    paymentId(newVal) {
      this.getData(newVal);
    }
  },
  created() {
    this.getData(this.paymentId);
  },
  methods: {
    close() {
      this.model = {};
      this.$emit('close', true);
    },
    getData(id) {
      if (!id) return;

      this.isLoading = true;

      this.$store.dispatch('getPayment', id)
        .then((payment) => {
          if (payment) this.model = payment;
          else failure(this, {message: 'Error getting payment.'});
        })
        .catch(() => failure(this, {message: 'Error getting payment.'}))
        .finally(() => this.isLoading = false);
    },
    paid() {
      this.isLoading = true;

      const date = this.selectedDate ? this.selectedDate.getTime() : null;
      const model = {
        id: this.model.id,
        paymentDate: this.model.paymentDate,
        percentage: this.model.percentage,
        paid: this.model.paid,
        month: this.model.month,
        year: this.model.year,
        createdOn: this.model.createdOn,
        editedOn: this.model.editedOn,
        paidDate: date,
      };
      
      this.$store.dispatch('updatePayment', model)
        .then((updatedPayment) => {
          if (updatedPayment) {
            this.$store.dispatch('hotReload');
            success(this, {message: 'Updated payment.'});
            this.close();
          } else {
            failure(this, {message: 'Error updating the payment.'});
          }
        })
        .catch(() => failure(this, {message: 'Error updating the payment.'}))
        .finally(() => this.isLoading = false);
    },
  },
});
</script>

<style lang="scss" scoped>
.paid-amount {
  font-size: 28px;
}

.paid-button {
  float: right;
}

.paid-date {
  text-align: left;
}

.paid-title {
  font-size: 36px;
}
</style>

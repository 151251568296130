<template>
  <b-modal
    v-model="isActive"
    has-modal-card
    trap-focus
    :destroy-on-hide="false"
    aria-role="dialog"
    aria-label="Profit"
    aria-modal
    @close="close"
  >
    <template>
      <b-loading v-model="isLoading" />
      <form action="">
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">{{ title }}</p>
            <button type="button" class="delete" @click="close" />
          </header>
          <section class="modal-card-body">
            <b-field class="label"
              label="Client"
              :type="{'is-danger': !clientValid}"
              :message="{'Need a client.': !clientValid}">
              <b-select v-model="model.client" expanded required>
                <option v-for="client in clients" :key="client.id" :value="client">
                  {{ `${client.firstName} ${client.lastName}` }}
                </option>
              </b-select>
            </b-field>
            <b-field class="label"
              label="Service Date"
              :type="{'is-danger': !serviceDateValid}"
              :message="{'Need a valid date.': !serviceDateValid}">
              <b-datepicker
                icon="calendar-today"
                v-model="model.serviceDate"
                trap-focus
                expanded />
            </b-field>
            <b-field class="label"
              label="Services"
              :type="{'is-danger': !serviceValid}"
              :message="{'Need at least one service.': !serviceValid}">
              <b-select native-size="2" v-model="model.service" expanded multiple>
                <option v-for="service in services" :key="service.id" :value="service.id">{{ service.service }}</option>
              </b-select>
            </b-field>
            <b-field class="label"
              label="Tips"
              :type="{'is-danger': !tipsValid}"
              :message="{'Set tips to at least 0.': !tipsValid}">
              <b-numberinput v-model="model.tips" :min="0" expanded required />
            </b-field>
            <b-field class="label"
              label="Cost"
              :type="{'is-danger': !costValid}"
              :message="{'Set cost to at least 0.': !costValid}">
              <b-numberinput v-model="model.cost" :min="0" expanded required />
            </b-field>
          </section>
          <footer class="modal-card-foot">
            <b-button v-if="!profitId" class="add-button" @click="addNewProfit">Add</b-button>
            <div v-else class="profit-buttons">
              <b-button type="is-danger" @click="deleteProfit">DELETE</b-button>
              <b-button type="is-dark" @click="update" inverted>Update</b-button>
            </div>
          </footer>
        </div>
      </form>
    </template>
  </b-modal>
</template>

<script lang="ts">
import Vue from 'vue';

import { failure, success } from '@/utility/messages';
import BASIC_SERVICES from '@/config/basicServices';
import { modelData, client, cost, sd, service, tips, isValid } from '@/mixins/checkers';
import { setDateFormat } from '@/utility/index';

export default Vue.extend({
  name: 'ProfitData',
  mixins: [
    modelData,
  ],
  props: {
    isModalActive: {
      type: Boolean,
      required: true,
    },
    profitId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      clients: [],
      isActive: false,
      isClientLoading: false,
      isProfitLoading: false,
    };
  },
  computed: {
    isAllValid() {
      return isValid(this.clientValid, this.serviceValid, this.tipsValid, this.costValid);
    },
    isLoading() {
      return this.isClientLoading || this.isProfitLoading;
    },
    services() {
      return BASIC_SERVICES;
    },
    title() {
      return this.profitId ? 'Edit Profit' : 'Add New Profit';
    },
    ...client,
    ...cost,
    ...sd,
    ...service,
    ...tips,
  },
  watch: {
    isModalActive(newVal) {
      this.isActive = newVal;
    },
    profitId(newVal) {
      if (!newVal) return;
      this.getClients();
      this.getProfit(newVal);
    },
  },
  created() {
    this.getClients();

    if (this.profitId) {
      this.getProfit(this.profitId);
    } else {
      this.model.service = [];
    }
  },
  methods: {
    addNewProfit() {
      if (!this.isAllValid) return;

      this.isProfitLoading = true;

      this.$store.dispatch('addNewProfit', this.model)
        .then((profit) => {
          if (profit) {
            this.$store.dispatch('hotReload');
            success(this, {message: 'Created new profit.'});
            this.close();
          } else {
            failure(this, {message: 'Error creating the profit.'});
          }
        })
        .catch(() => failure(this, {message: 'Error creating the profit.'}))
        .finally(() => this.isProfitLoading = false);
    },
    close() {
      this.model = {};
      this.$emit('close', true);
    },
    deleteProfit() {
      this.isProfitLoading = true;

      this.$store.dispatch('deleteProfit', this.model.id)
        .then((isSuccess) => {
          if (isSuccess) {
            this.$store.dispatch('hotReload');
            success(this, {message: 'Profit deleted.'});
            this.close();
          } else {
            failure(this, {message: 'Error deleting the profit.'});
          }
        })
        .catch(() => failure(this, {message: 'Error deleting the profit.'}))
        .finally(() => this.isProfitLoading = false);
    },
    getClients() {
      this.isClientLoading = true;

      this.$store.dispatch('getAllClients')
        .then((clients) => this.clients = clients)
        .catch(() => failure(this, {message: 'Error retrieving clients.'}))
        .finally(() => this.isClientLoading = false);
    },
    getProfit(id) {
      if (!id) return;

      this.isProfitLoading = true;

      this.$store.dispatch('getProfit', id)
        .then((profit) => {
          if (profit) {
            this.model = {...profit, serviceDate: setDateFormat(profit.serviceDate)};
          } else {
            failure(this, {message: 'Error loading profit.'});
          }
        })
        .catch(() => failure(this, {message: 'Error loading profit.'}))
        .finally(() => this.isProfitLoading = false);
    },
    update() {
      if (!this.isAllValid) return;

      this.isProfitLoading = true;

      const updateProfit = {
        id: this.model.id,
        clientId: this.model.client.id,
        service: this.model.service,
        serviceDate: new Date(this.model.serviceDate).getTime(),
        tips: this.model.tips,
        cost: this.model.cost,
        month: this.model.month,
        year: this.model.year,
        day: this.model.day,
        createdOn: this.model.createdOn,
        editedOn: new Date().getTime(),
      };

      this.$store.dispatch('updateProfit', updateProfit)
        .then((profit) => {
          if (profit) {
            this.$store.dispatch('hotReload');
            success(this, {message: 'Updated profit.'});
            this.close();
          } else {
            failure(this, {message: 'Error updating the profit.'});
          }
        })
        .catch(() => failure(this, {message: 'Error updating the profit.'}))
        .finally(() => this.isProfitLoading = false);
    },
  },
});
</script>

<style lang="scss" scoped>
.add-button {
  float: right;
}

.label {
  text-align: left;
}

.profit-buttons {
  float: right;

  button:last-child {
    margin-left: 12px;
  }
}

.profit-header {
  font-size: 24px;
}
</style>

const failure = (that, { duration, message, position }) => {
  that.$buefy.toast.open({
    duration: duration ? duration : 5000,
    message: message ? message : 'Error',
    position: position ? position : 'is-bottom',
    type: 'is-danger',
  });
};

const success = (that, { duration, message, position }) => {
  that.$buefy.toast.open({
    duration: duration ? duration : 5000,
    message: message ? message : 'Success',
    position: position ? position : 'is-bottom',
    type: 'is-success',
  });
};

export { failure, success };

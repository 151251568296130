const GiftCardsData = {
  getters: {
    
  },
  actions: {
    addNewGiftCard(context, gc) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverAddNewGiftCard', gc)
            .then((card) => resolve(card));
        } catch {
          reject(null);
        }
      });
    },
    getAllGiftCards(context) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverGetAllGiftCards')
            .then((cards) => resolve(cards));
        } catch {
          reject([]);
        }
      });
    },
    getGiftCard(context, id) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverGetGiftCard', id)
            .then((card) => resolve(card));
        } catch {
          reject(null);
        }
      });
    },
    updateGiftCard(context, gc) {
      return new Promise((resolve, reject) => {
        try {
          context.dispatch('serverUpdateGiftCard', gc)
            .then((card) => resolve(card));
        } catch {
          reject(null);
        }
      });
    },
  },
  mutations: {
    
  },
  state: {
    
  },
};

export default GiftCardsData;

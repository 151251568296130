const checkValue = ({ value, type }) => {
  if (!type) return false;
      
  const regex = /[0-9]{3}-{1}[0-9]{3}-{1}[0-9]{4}/g; // Phone number

  switch (type) {
    case 'array':
      return value && value instanceof Array;
    case 'number':
      return !isNaN(value);
    case 'object':
      return value != null;
    case 'phone':
      return value && regex.exec(value);
    case 'string':
      return value && value.length > 0;
    default:
      return false;
  }
};

const getMonthString = (monthNum, full = false) => {
  switch (monthNum) {
    case 0: return full ? 'January' : 'Jan';
    case 1: return full ? 'February' : 'Feb';
    case 2: return full ? 'March' : 'Mar';
    case 3: return full ? 'April' : 'Apr';
    case 4: return full ? 'May' : 'May';
    case 5: return full ? 'June' : 'Jun';
    case 6: return full ? 'July' : 'Jul';
    case 7: return full ? 'August' : 'Aug';
    case 8: return full ? 'September' : 'Sep';
    case 9: return full ? 'October' : 'Oct';
    case 10: return full ? 'November' : 'Nov';
    case 11: return full ? 'December' : 'Dec';
    default: return 'N/A';
  }
};

const getPaymentCalc = (total, deductions, percentage, tips) => {
  return ((total - deductions) * (percentage / 100) + tips).toFixed(2);
};

const setDateFormat = (ms) => {
  return ms && !isNaN(ms) ? new Date(Number(ms)) : new Date();
};

const setShortDateFormat = (ms) => {
  const date = setDateFormat(ms);
  if (!date) return '';
  const h = date.getHours() > 9 ? date.getHours() : `0${date.getHours()}`;
  const m = date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`;
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} ${h}:${m}`;
};

export { checkValue, getMonthString, getPaymentCalc, setDateFormat, setShortDateFormat };

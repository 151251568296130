import App from './App';
import Vue from 'vue';
import Vuex from 'vuex';
import router from './router'

import 'es6-promise/auto';

import AppointmentData from '@/store/appointmentData';
import ClientData from '@/store/clientData';
import ExpenseData from '@/store/expenseData';
import GiftCardsData from '@/store/giftCardsData';
import Main from '@/store/main';
import PaymentsData from '@/store/paymentsData';
import ProfitData from '@/store/profitData';
import Server from '@/store/server';
import { setShortDateFormat } from '@/utility/index';

Vue.config.productionTip = false;
Vue.use(Vuex);

const store = [AppointmentData, ClientData, GiftCardsData, ExpenseData, PaymentsData, ProfitData, Server, Main].reduce((stores, store) => {
  Object.keys(store).forEach((key) => stores[key] = {...stores[key], ...store[key]});
  return stores;
});

Vue.filter('toCurrency', (val) => {
  if (val == null || isNaN(val)) return '';
  return `$${val.toFixed(2)}`;
});
Vue.filter('toDate', (val) => {
  return val ? setShortDateFormat(val) : '';
});

const VM = new Vue({
  router,
  render: h => h(App),
  store: new Vuex.Store(store),
}).$mount('#app');

export default VM;

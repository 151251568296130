import { API } from 'aws-amplify';
import * as mutations from '@/graphql/mutations';
import * as queries from '@/graphql/queries';

import { AppointmentStatus, Client, Expense, GiftCard, Payment, Profit } from '@/models/index';
import { getPaymentCalc } from '@/utility/index';
import DateQuery from '@/models/dateQuery';

// Server sepecific constants
const API_KEY = 'API_KEY';

/**
 * checkForData
 * 
 * Checks a response from the server for data and returns that data, an empty array, or null.
 * 
 * @param data (any): the data to check (the response from the server)
 * @param lastProp (string): the last prop in the chain to check that would be returned: i.e. "updatePostNumber"
 * @param isArray (boolean)[false]: if is an array, returns the proper "items" part of the return statement
 * @returns (any)
 */
 const checkForData = (data, lastProp, isArray = false) => {
  if (!data || !data.data || !lastProp || !data.data[lastProp]) return null;
  return isArray ? data.data[lastProp].items : data.data[lastProp];
};

const Server = {
  getters: {

  },
  actions: {
    async serverAddNewClient(context, client) {
      try {
        const newClient = new Client({
          firstName: client.firstName,
          lastName: client.lastName,
          phoneNumber: client.phoneNumber,
          comments: client.comments ? client.comments : 'None',
        });
        const data = await API.graphql({query: mutations.createClient, variables: {input: newClient}});
        return checkForData(data, 'createClient');
      } catch {
        return null;
      }
    },
    async serverAddNewExpense(context, expense) {
      try {
        const nowTime = new Date().getTime();
        const newExpense = new Expense({
          cost: expense.cost,
          createdOn: nowTime,
          editedOn: nowTime,
          expense: expense.expense,
          month: expense.month,
          profitId: expense.profit ? expense.profit.id : null,
          year: expense.year,
        });
        const data = await API.graphql({query: mutations.createExpense, variables: {input: newExpense}});
        return checkForData(data, 'createExpense');
      } catch {
        return null;
      }
    },
    async serverAddNewGiftCard(context, gc) {
      try {
        const now = new Date();
        const time = now.getTime();
        const newGc = new GiftCard({
          cardId: gc.cardId,
          createdOn: time,
          day: now.getDate(),
          editedOn: time,
          initialTotal: gc.initialTotal,
          month: now.getMonth(),
          remaining: gc.initialTotal,
          remainingServices: gc.service,
          service: gc.service,
          tip: gc.tips,
          year: now.getFullYear(),
        });
        const data = await API.graphql({query: mutations.createGiftCard, variables: {input: newGc}});
        return checkForData(data, 'createGiftCard');
      } catch {
        return null;
      }
    },
    async serverAddNewProfit(context, profit) {
      try {
        const nowTime = new Date().getTime();
        const service = profit.serviceDate;
        const newProfit = new Profit({
          clientId: profit.client.id,
          cost: profit.cost,
          createdOn: nowTime,
          day: service.getDate(),
          editedOn: nowTime,
          month: service.getMonth(),
          service: profit.service,
          serviceDate: service.getTime(),
          tips: profit.tips,
          year: service.getFullYear(),
        });
        const data = await API.graphql({query: mutations.createProfit, variables: {input: newProfit}});
        return checkForData(data, 'createProfit');
      } catch {
        return null;
      }
    },
    async serverCreateAppointment(context, appointment) {
      try {
        const data = await API.graphql({query: mutations.createAppointment, variables: {input: appointment}, authMode: API_KEY});
        return checkForData(data, 'createAppointment');
      } catch {
        return null;
      }
    },
    async serverCreatePayment(context, { day, month, year, percentage }) {
      try {
        const dayNum = day === '15th' ? 15 : day.substr(0, 2);
        const dateQuery = new DateQuery(year, month);

        const allProfits = await context.dispatch('serverGetProfits', dateQuery);
        const allExpenses = await context.dispatch('serverGetExpenses', dateQuery);

        if (!allProfits || !allExpenses) return null;

        const profits = allProfits.filter((p) => dayNum === 15 ? p.day < 16 : p.day > 15);
        const expenses = allExpenses.filter((e) => {
          if (e.profit && e.profit.id) {
            return profits.find((p) => p.id === e.profit.id);
          }
          return false;
        });

        const total = profits.reduce((tot, prof) => tot += prof.cost, 0);
        const deductions = expenses.filter((exp) => exp.profit).reduce((tot, exp) => tot += exp.cost, 0);
        const tips = profits.reduce((tot, prof) => tot += prof.tips, 0);
        const amount = getPaymentCalc(total, deductions, percentage, tips);
        const now = new Date().getTime();

        const newPayment = new Payment({
          createdOn: now,
          editedOn: now,
          month,
          paid: amount && amount > 0 ? Number(amount) : 0,
          paidDate: 0,
          paymentDate: day,
          percentage: Number(percentage),
          year,
        });
        const payments = await context.dispatch('serverGetPayments', dateQuery);

        if (payments) {
          const found = payments.find((p) => p.paymentDate === day);

          if (found) {
            // Update instead of create...
            const updatedPayment = {
              id: found.id,
              paymentDate: newPayment.paymentDate,
              percentage: newPayment.percentage,
              paid: newPayment.paid,
              paidDate: newPayment.paidDate,
              month: newPayment.month,
              year: newPayment.year,
              createdOn: newPayment.createdOn,
              editedOn: newPayment.editedOn,
            };
            return await context.dispatch('serverUpdatePayment', updatedPayment);
          }
        }

        const data = await API.graphql({query: mutations.createPayment, variables: {input: newPayment}});
        return checkForData(data, 'createPayment');
      } catch {
        return null;
      }
    },
    async serverDeleteAppointment(context, id) {
      try {
        const data = await API.graphql({query: mutations.deleteAppointment, variables: {input: {id}}});
        return checkForData(data, 'deleteAppointment') ? true : false;
      } catch {
        return false;
      }
    },
    async serverDeleteClient(context, id) {
      try {
        const data = await API.graphql({query: mutations.deleteClient, variables: {input: {id}}});
        return checkForData(data, 'deleteClient') ? true : false;
      } catch {
        return false;
      }
    },
    async serverDeleteExpense(context, id) {
      try {
        const data = await API.graphql({query: mutations.deleteExpense, variables: {input: {id}}});
        return checkForData(data, 'deleteExpense') ? true : false;
      } catch {
        return false;
      }
    },
    async serverDeleteProfit(context, id) {
      try {
        const data = await API.graphql({query: mutations.deleteProfit, variables: {input: {id}}});
        return checkForData(data, 'deleteProfit') ? true : false;
      } catch {
        return false;
      }
    },
    async serverGetAllClients() {
      try {
        const data = await API.graphql({query: queries.listClients});
        const clients = checkForData(data, 'listClients', true);
        clients.sort((a, b) => a.lastName > b.lastName ? -1 : a.lastName < b.lastName ? 1 : a.firstName > b.firstName ? -1 : a.firstName < b.firstName ? 1 : 0);
        return clients;
      } catch {
        return [];
      }
    },
    async serverGetAllGiftCards() {
      try {
        const data = await API.graphql({query: queries.listGiftCards});
        const cards = checkForData(data, 'listGiftCards', true);
        cards.sort((a, b) => a.cardId > b.cardId ? -1 : a.cardId < b.cardId ? 1 : 0);
        return cards;
      } catch {
        return [];
      }
    },
    async serverGetAppointment(context, id) {
      try {
        const data = await API.graphql({query: queries.getAppointment, variables: {id}});
        return checkForData(data, 'getAppointment');
      } catch {
        return null;
      }
    },
    async serverGetAppointments() {
      try {
        const filter = {
          or: [
            {status: {eq: AppointmentStatus.REQUEST}},
            {status: {eq: AppointmentStatus.CONFIRMED}},
          ],
        };
        const data = await API.graphql({query: queries.listAppointments, variables: {filter}});
        const appts = checkForData(data, 'listAppointments', true);
        appts.sort((a, b) => a.requestedFor > b.requestedFor ? -1 : a.requestedFor < b.requestedFor ? 1 : 0);
        return appts;
      } catch {
        return [];
      }
    },
    async serverGetClient(context, id) {
      try {
        const data = await API.graphql({query: queries.getClient, variables: {id}});
        return checkForData(data, 'getClient');
      } catch {
        return null;
      }
    },
    async serverGetExpense(context, id) {
      try {
        const data = await API.graphql({query: queries.getExpense, variables: {id}});
        return checkForData(data, 'getExpense');
      } catch {
        return null;
      }
    },
    async serverGetExpenses(context, dateQuery) {
      try {
        const data = await API.graphql({query: queries.expenseByDate, variables: {
          year: dateQuery.year,
          monthCreatedOn: {beginsWith: {month: dateQuery.month}},
        }});
        return checkForData(data, 'expenseByDate', true);
      } catch {
        return [];
      }
    },
    async serverGetGiftCard(context, id) {
      try {
        const data = await API.graphql({query: queries.getGiftCard, variables: {id}});
        return checkForData(data, 'getGiftCard');
      } catch {
        return null;
      }
    },
    async serverGetPayment(context, id) {
      try {
        const data = await API.graphql({query: queries.getPayment, variables: {id}});
        return checkForData(data, 'getPayment');
      } catch {
        return null;
      }
    },
    async serverGetPayments(context, dateQuery) {
      try {
        const data = await API.graphql({query: queries.paymentByDate, variables: {
          year: dateQuery.year,
          monthPaymentDate: {beginsWith: {month: dateQuery.month}},
          sortDirection: 'ASC',
        }});
        return checkForData(data, 'paymentByDate', true);
      } catch {
        return [];
      }
    },
    async serverGetProfit(context, id) {
      try {
        const data = await API.graphql({query: queries.getProfit, variables: {id}});
        return checkForData(data, 'getProfit');
      } catch {
        return null;
      }
    },
    async serverGetProfits(context, dateQuery) {
      try {
        const range = {month: dateQuery.month};
        if (dateQuery.day > -1) range.day = dateQuery.day;

        const data = await API.graphql({query: queries.profitByDate, variables: {
          year: dateQuery.year,
          monthDay: {beginsWith: range},
        }});
        return checkForData(data, 'profitByDate', true);
      } catch {
        return [];
      }
    },
    async serverUpdateAppointment(context, updatedAppointment) {
      try {
        const data = await API.graphql({query: mutations.updateAppointment, variables: {input: updatedAppointment}});
        return checkForData(data, 'updateAppointment');
      } catch {
        return null;
      }
    },
    async serverUpdateClient(context, updatedClient) {
      try {
        const data = await API.graphql({query: mutations.updateClient, variables: {input: updatedClient}});
        return checkForData(data, 'updateClient');
      } catch {
        return null;
      }
    },
    async serverUpdateExpense(context, updatedExpense) {
      try {
        const data = await API.graphql({query: mutations.updateExpense, variables: {input: updatedExpense}});
        return checkForData(data, 'updateExpense');
      } catch {
        return null;
      }
    },
    async serverUpdateGiftCard(context, gc) {
      try {
        const data = await API.graphql({query: mutations.updateGiftCard, variables: {input: gc}});
        return checkForData(data, 'updateGiftCard');
      } catch {
        return null;
      }
    },
    async serverUpdatePayment(context, updatedPayment) {
      try {
        const data = await API.graphql({query: mutations.updatePayment, variables: {input: updatedPayment}});
        return checkForData(data, 'updatePayment');
      } catch {
        return null;
      }
    },
    async serverUpdateProfit(context, updatedProfit) {
      try {
        const data = await API.graphql({query: mutations.updateProfit, variables: {input: updatedProfit}});
        return checkForData(data, 'updateProfit');
      } catch {
        return null;
      }
    },
  },
  mutations: {

  },
  state: {

  },
};

export default Server;

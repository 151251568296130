<template>
  <div class="bn-container bn-column bn-site-color bn-site-background-color">
    <client-data :isModalActive="isClientModalActive" :clientId="clientId" @close="isClientModalActive = false" />
    <profit-data :isModalActive="isProfitModalActive" :profitId="profitId" @close="isProfitModalActive = false" />
    <b-table
      class="profits-table"
      :loading="isLoading"
      :data="data"
      :striped="true"
      :narrowed="true"
      :hoverable="true"
      :selected.sync="selected"
      :mobile-cards="true">
      <b-table-column label="Services" subheading="Total" v-slot="props">
        {{ props.row.service.join(", ") }}
      </b-table-column>
      <b-table-column label="Client" v-slot="props">
        <span v-if="!props.row.client">N/A</span>
        <span v-else class="subcomponent" @click="showClient(props.row.client)">
          {{ `${props.row.client.firstName} ${props.row.client.lastName}` }}
        </span>
      </b-table-column>
      <b-table-column label="Date" v-slot="props">
        {{ new Date(props.row.serviceDate).toLocaleDateString() }}
      </b-table-column>
      <b-table-column label="Tips" :subheading="tipsTotal | toCurrency" v-slot="props">
        {{ props.row.tips | toCurrency }}
      </b-table-column>
      <b-table-column label="Cost" :subheading="costTotal | toCurrency" v-slot="props">
        <b-tag type="is-success is-light">{{ props.row.cost | toCurrency }}</b-tag>
      </b-table-column>
    </b-table>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';

import ClientData from '@/components/subcomponent/ClientData.vue';
import ProfitData from '@/components/subcomponent/ProfitData.vue';

export default Vue.extend({
  name: 'ProfitsTable',
  components: {
    ClientData,
    ProfitData,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      clientId: '',
      dontOpen: false,
      isClientModalActive: false,
      isProfitModalActive: false,
      profitId: '',
      selected: null,
    };
  },
  watch: {
    isClientModalActive(newVal) {
      this.$store.dispatch('modalOpen', newVal);
    },
    isProfitModalActive(newVal) {
      this.$store.dispatch('modalOpen', newVal);
    },
    selected(val) {
      if (this.dontOpen) {
        this.dontOpen = false;
        return;
      }
      this.editProfit(val);
    },
  },
  computed: {
    costTotal() {
      return this.data.length === 0 ? 0 : this.data.reduce((total, profit) => total + profit.cost, 0);
    },
    tipsTotal() {
      return this.data.length === 0 ? 0 : this.data.reduce((total, profit) => {
        if (profit.tips) return total + profit.tips;
        return total;
      }, 0);
    },
  },
  methods: {
    editProfit({ id }) {
      this.profitId = id;
      this.isProfitModalActive = true;
    },
    showClient({ id }) {
      this.dontOpen = true;
      this.clientId = id;
      this.isClientModalActive = true;
    },
  },
});
</script>

<style lang="scss" scoped>
.profits-table {
  cursor: pointer;
  text-align: left;
  width: 100%;
}

.subcomponent:hover {
  text-decoration: underline
}
</style>

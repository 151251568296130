<template>
  <div class="selection-container">
    <div class="selection-header">
      Artwork Examples Below
    </div>
    <div>
      <photo-card :images="artwork.images" :setup="PICTURE_SETUP.THREE_SINGLES" />
    </div>
    <div class="selection-text">The above images are example difficulties and cost is based on the number of fingers with this art style.</div>
    <div class="columns selection-fingers">
      <div class="column is-4">&nbsp;</div>
      <div class="column is-4">
        <b-field label="Fingers Applied">
          <b-numberinput
            class="number-input"
            :class="{'number-input-large': isLargeMode, 'number-input-small': !isLargeMode}"
            controls-position="compact"
            max="10"
            min="1"
            type="is-dark"
            v-model="fingers"
            controls-rounded />
        </b-field>
      </div>
      <div class="column is-4">&nbsp;</div>
    </div>
    <div class="selection-cost">
      Cost: ${{ cost }}
    </div>
    <div class="columns">
      <div class="column is-8">&nbsp;</div>
      <div class="column is-4">
        <b-button type="is-light" class="clear-button" @click="close" inverted>Close</b-button>
        <b-button type="is-dark" @click="add" inverted>Add</b-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters } from 'vuex';

import PhotoCard from '@/components/PhotoCard.vue';
import PICTURE_SETUP from '@/enums/PictureSetup';

export default Vue.extend({
  name: 'ArtworkPriceSelection',
  components: {
    PhotoCard,
  },
  props: {
    artwork: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      cost: 0,
      fingers: 0,
      PICTURE_SETUP,
    };
  },
  computed: {
    ...mapGetters([
      'isLargeMode',
    ]),
  },
  watch: {
    fingers(val) {
      if (isNaN(val)) return;

      try {
        const num = Number(val);
        this.cost = this.artwork.costs.find((c) => c.count === num).cost;
      } catch {
        this.cost = 0;
      }
    },
  },
  created() {
    this.fingers = 10;
  },
  methods: {
    add() {
      this.$store.dispatch('setArtworkFingers', { artwork: this.artwork, cost: this.cost });
      this.close();
    },
    close() {
      this.$store.dispatch('closeModal');
    },
  },
});
</script>

<style lang="scss" scoped>
.clear-button {
  margin-right: 12px;
}

.number-input-large {
  margin-left: 30px;
}

.number-input-small {
  margin-left: 60px;
}

.selection-cost {
  padding-bottom: 12px;
}

.selection-fingers {
  padding-bottom: 12px;

  input {
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
  }

  label {
    position: relative;
    top: 2px;
  }
}

.selection-header {
  font-size: 20px;
  padding-bottom: 16px;
}

.selection-container {
  color: white;
  font-size: 18px;
}

.selection-text {
  padding-bottom: 12px;
  padding-top: 12px;
}
</style>

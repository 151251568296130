/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:327bc367-d9e8-4868-a1c2-181432e0879f",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_6btbV9jj9",
    "aws_user_pools_web_client_id": "3ktsvrdr9q3unbsjgj9ii8a7ft",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://6eus7erf6fdqfdzzm3shcn77qa.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-x5ufrfvdbra4hnnke3u55wycya"
};


export default awsmobile;

const BASIC_SERVICES = [
  {
    cost: 25,
    id: 'mani',
    service: 'Manicure',
    short: 'Mani',
  },
  {
    cost: 35,
    id: 'mani-gel',
    service: 'Mani (Gel)',
    short: 'M (G)',
  },
  {
    cost: 40,
    id: 'builder-gel',
    service: 'Builder Gel',
    short: 'Build',
  },
  {
    cost: 55,
    id: 'polygel',
    service: 'Polygel',
    short: 'Poly',
  },
  {
    cost: 35,
    id: 'mani-spa',
    service: 'Spa Mani',
    short: 'Spa M',
  },
  {
    cost: 45,
    id: 'mani-spa-gel',
    service: 'Spa Mani (Gel)',
    short: 'S/M (G)',
  },
  {
    cost: 35,
    id: 'pedi',
    service: 'Pedicure',
    short: 'Pedi',
  },
  {
    cost: 45,
    id: 'pedi-gel',
    service: 'Pedi (Gel)',
    short: 'P (G)',
  },
  {
    cost: 45,
    id: 'pedi-spa',
    service: 'Spa Pedi',
    short: 'Spa P',
  },
  {
    cost: 55,
    id: 'pedi-spa-gel',
    service: 'Spa Pedi (Gel)',
    short: 'S/P (G)',
  },
  {
    cost: 20,
    id: 'gel-removal',
    service: 'Removal (Gel)',
    short: 'Rem (G)',
  },
  {
    cost: 30,
    id: 'dip-removal',
    service: 'Removal (Dip)',
    short: 'Rem (D)',
  },
  {
    cost: 5,
    id: 'mani-kid',
    service: 'Kid Mani',
    short: 'Kid M',
  },
  {
    cost: 5,
    id: 'pedi-kid',
    service: 'Kid Pedi',
    short: 'Kid P',
  },
];

export default BASIC_SERVICES;
<template>
  <div v-if="isLoggedIn" class="bn-container bn-site-color bn-site-background-color">
    <div class="bn-column bn-card bn-site-background-color tab-container" :class="{'popup': isModalActive}">
      <div class="bn-column bn-luxury admin-title">Profits/Losses</div>
      <div class="bn-container content-container">
        <div :class="{'month-selector-large': isLargeMode, 'month-selector-small': !isLargeMode}">
          <b-field label="Date" label-position="inside">
            <b-datepicker
              type="month"
              icon="calendar-today"
              position="is-bottom-left"
              size="is-small"
              v-model="selectedDate"
              :mobile-native="false"
              trap-focus />
          </b-field>
        </div>
        <div class="bn-column">
          <b-tabs class="admin-tabs" type="is-boxed">
            <b-tab-item label="Profits" icon="trending-up">
              <profits-table class="tables" :is-loading="isProfitLoading" :data="profits" />
              <b-button class="add-button" @click="addNewProfit">Add</b-button>
            </b-tab-item>
            <b-tab-item label="Expenses" icon="trending-down">
              <expenses-table class="tables"
                :is-loading="isExpenseLoading"
                :data="expenses" 
                :month="month" 
                :year="year" />
                <b-button class="add-button" @click="addNewExpense">Add</b-button>
            </b-tab-item>
          </b-tabs>
        </div>
      </div>
    </div>
    <div class="bn-site-background-color bn-card rollup-container popup">
      <div class="bn-bank monthly-totals">Monthly Totals</div>
      <rollup-table class="rollup-table tables" :is-loading="isRollupLoading" :data="rollup" />
    </div>
    <b-button class="admin-button" @click="$router.push('/admin')">
      <i class="fa fa-arrow-left"></i> ADMIN
    </b-button>
    <expense-data
      :isModalActive="isExpenseModalActive"
      :month="month"
      :year="year"
      @close="isExpenseModalActive = false"
    />
    <profit-data :isModalActive="isProfitModalActive" @close="isProfitModalActive = false" />
  </div>
  <div v-else>
    <br><br><br><br><br><br><br><br><br><br><br><br>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters } from 'vuex';

import { failure } from '@/utility/messages';
import ADMIN_ACCESS from '@/mixins/adminAccess';
import DateQuery from '@/models/dateQuery';
import ExpenseData from '@/components/subcomponent/ExpenseData.vue';
import ExpensesTable from '@/components/subcomponent/ExpensesTable.vue';
import HOT_RELOAD from '@/mixins/hotReload';
import ProfitData from '@/components/subcomponent/ProfitData.vue';
import ProfitsTable from '@/components/subcomponent/ProfitsTable.vue';
import Rollup from '@/models/rollup';
import RollupTable from '@/components/subcomponent/RollupTable.vue';

export default Vue.extend({
  name: 'ProfitLoss',
  mixins: [
    ADMIN_ACCESS,
    HOT_RELOAD,
  ],
  components: {
    ExpenseData,
    ExpensesTable,
    ProfitData,
    ProfitsTable,
    RollupTable,
  },
  data() {
    return {
      expenses: [],
      isExpenseLoading: false,
      isExpenseModalActive: false,
      isProfitLoading: false,
      isProfitModalActive: false,
      profits: [],
      selectedDate: new Date(),
    };
  },
  computed: {
    isModalActive() {
      return this.isExpenseModalActive || this.isProfitModalActive;
    },
    isRollupLoading() {
      return this.isExpenseLoading || this.isProfitLoading;
    },
    month() {
      return this.selectedDate ? this.selectedDate.getMonth() : 0;
    },
    rollup() {
      if (this.isLoading) return [];
      let rollups = this.profits.map((profit) => new Rollup(
        true,
        `${profit.client.firstName} ${profit.client.lastName}`,
        profit.cost,
        profit.tips,
      ));
      rollups = [...rollups, ...this.expenses.map((expense) => new Rollup(false, expense.expense, expense.cost))];
      return rollups;
    },
    year() {
      return this.selectedDate ? this.selectedDate.getFullYear() : 2000;
    },
    ...mapGetters([
      'isLargeMode',
    ]),
  },
  watch: {
    isModalActive(newVal) {
      this.$store.dispatch('modalOpen', newVal);
    },
    selectedDate() {
      this.getData();
    },
  },
  created() {
    this.getData();
  },
  methods: {
    addNewExpense() {
      if (!this.selectedDate) return;

      this.isExpenseModalActive = true;
    },
    addNewProfit() {
      this.isProfitModalActive = true;
    },
    getData() {
      if (!this.selectedDate) return;

      this.isExpenseLoading = true;
      this.isProfitLoading = true;

      const date = new Date(this.selectedDate);
      const month = date.getMonth();
      const year = date.getFullYear();
      const dateQuery = new DateQuery(year, month);

      this.$store.dispatch('getProfits', dateQuery)
        .then((profits) => this.profits = profits)
        .catch(() => failure(this, {message: 'Error retrieving profits.'}))
        .finally(() => this.isProfitLoading = false);

      this.$store.dispatch('getExpenses', dateQuery)
        .then((expenses) => this.expenses = expenses)
        .catch(() => failure(this, {message: 'Error retrieving expenses.'}))
        .finally(() => this.isExpenseLoading = false);
    },
  },
});
</script>

<style lang="scss" scoped>
.add-button {
  float: right;
  margin-top: 8px;
}

.admin-button {
  float: left;
  margin-left: 12px;
}

.admin-tabs::v-deep li.is-active a {
  color: black !important;
}

.admin-tabs::v-deep a {
  color: white;
}

.admin-tabs::v-deep a:hover {
  color: black;
  opacity: 0.8;
}

.admin-title {
  font-size: 60px;
  padding-bottom: 28px;
  padding-top: 28px;
}

.content-container {
  padding: 0px 12px;
  position: relative;
}

.month-selector-large {
  position: absolute;
  right: 12px;
  top: -4px;
}

.month-selector-small {
  padding-bottom: 8px;
}

.monthly-totals {
  font-size: 36px;
}

.popup {
  z-index: 0 !important;
}

.rollup-container {
  margin: 0px 12px;
  padding-bottom: 24px;
  position: relative;
  width: 100%;
}

.rollup-table {
  padding-top: 24px;
  text-align: left;
}

.tab-container {
  padding-bottom: 28px;
}

.tables {
  max-height: 500px;
  overflow-y: scroll;
}

.year-selector-large {
  position: absolute;
  right: 0px;
  top: 0px;
}

.year-selector-small {
  padding-bottom: 8px;
}
</style>

<template>
  <div class="bn-container bn-column bn-site-color bn-site-background-color">
    <expense-data
      :isModalActive="isExpenseModalActive"
      :expenseId="expenseId"
      :month="this.month"
      :year="this.year"
      @close="isExpenseModalActive = false"
    />
    <profit-data :isModalActive="isProfitModalActive" :profitId="profitId" @close="isProfitModalActive = false" />
    <b-table
      class="expenses-table"
      :loading="isLoading"
      :data="data"
      :striped="true"
      :narrowed="true"
      :hoverable="true"
      :selected.sync="selected"
      :mobile-cards="true">
      <b-table-column label="Expense" subheading="Total" v-slot="props">
        {{ props.row.expense }}
      </b-table-column>
      <b-table-column label="Associated Profit" v-slot="props">
        <span v-if="!props.row.profit">N/A</span>
        <span v-else class="subcomponent" @click="showProfit(props.row.profit)">
          {{ `${props.row.profit.id}` }}
        </span>
      </b-table-column>
      <b-table-column label="Cost" :subheading="costTotal | toCurrency" v-slot="props">
        <b-tag type="is-danger is-light">{{ props.row.cost | toCurrency }}</b-tag>
      </b-table-column>
    </b-table>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';

import ExpenseData from '@/components/subcomponent/ExpenseData.vue';
import ProfitData from '@/components/subcomponent/ProfitData.vue';

export default Vue.extend({
  name: 'ExpensesTable',
  components: {
    ExpenseData,
    ProfitData,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    month: {
      type: Number,
      required: true,
    },
    year: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      dontOpen: false,
      expenseId: '',
      isExpenseModalActive: false,
      isProfitModalActive: false,
      profitId: '',
      selected: null,
    };
  },
  watch: {
    isExpenseModalActive(newVal) {
      this.$store.dispatch('modalOpen', newVal);
    },
    isProfitModalActive(newVal) {
      this.$store.dispatch('modalOpen', newVal);
    },
    selected(val) {
      if (this.dontOpen) {
        this.dontOpen = false;
        return;
      }
      this.editExpense(val);
    },
  },
  computed: {
    costTotal() {
      return this.data.length === 0 ? 0 : this.data.reduce((total, profit) => total + profit.cost, 0);
    },
  },
  methods: {
    editExpense({ id }) {
      this.expenseId = id;
      this.isExpenseModalActive = true;
    },
    showProfit({ id }) {
      this.dontOpen = true;
      this.profitId = id;
      this.isProfitModalActive = true;
    },
  },
});
</script>

<style lang="scss" scoped>
.expenses-table {
  cursor: pointer;
  text-align: left;
  width: 100%;
}

.subcomponent:hover {
  text-decoration: underline
}
</style>

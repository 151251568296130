import About from '@/views/About.vue';
import Admin from '@/views/Admin.vue';
import Appointment from '@/views/Appointment.vue';
import AppointmentEdit from '@/views/AppointmentEdit.vue';
import Clients from '@/views/Clients.vue';
// import Contact from '@/views/Contact.vue';
import GiftCards from '@/views/GiftCards.vue';
import Logout from '@/views/Logout.vue';
import Payments from '@/views/Payments.vue';
import Photos from '@/views/Photos.vue';
import Pricing from '@/views/Pricing.vue';
import ProfitLoss from '@/views/ProfitLoss.vue';

const LINKS = [
  {
    component: Photos,
    link: '/photos',
    inMenu: true,
    title: 'Photos',
  },
  {
    component: Pricing,
    link: '/pricing',
    inMenu: true,
    title: 'Pricing',
  },
  {
    component: Appointment,
    link: '/appointment',
    inMenu: true,
    title: 'Appointment',
  },
  {
    component: About,
    link: '/about',
    inMenu: true,
    title: 'About',
  },
  // {
  //   component: Contact,
  //   link: '/contact',
  //   inMenu: true,
  //   title: 'Contact',
  // },
  {
    component: Admin,
    link: '/admin',
    inMenu: false,
    title: 'Admin',
  },
  {
    component: Logout,
    link: '/logout',
    inMenu: false,
    title: 'Logout',
  },
  {
    component: AppointmentEdit,
    link: '/admin/appointment/:id',
    inMenu: false,
    title: 'Appointment',
  },
  {
    component: ProfitLoss,
    link: '/admin/profitloss',
    inMenu: false,
    title: 'ProfitLoss',
  },
  {
    component: Clients,
    link: '/admin/clients',
    inMenu: false,
    title: 'Clients',
  },
  {
    component: Payments,
    link: '/admin/payments',
    inMenu: false,
    title: 'Payments',
  },
  {
    component: GiftCards,
    link: '/admin/giftcards',
    inMenu: false,
    title: 'GiftCards',
  },
];

export default LINKS;

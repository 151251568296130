import { DataStore, Predicates, SortDirection } from "aws-amplify";

const Main = {
  getters: {
    getArtworkFingers: (state) => state.artworkFingers,
    getContent: (state) => state.content,
    getError: (state) => state.error,
    getProps: (state) => state.props,
    hotReload: (state) => state.hotReload,
    isLargeMode: (state) => state.isLargeMode,
    isLoginModalOpen: (state) => state.isLoginModalOpen,
    isModalActive: (state) => state.isModalActive,
    isModalOpen: (state) => state.isModalOpen,
  },
  actions: {
    closeLoginModal(context) {
      context.commit('closeLoginModal');
    },
    closeModal(context) {
      context.commit('closeModal');
    },
    coldReload(context) {
      context.commit('setHotReload', false);
    },
    deleteModel(context, { classType, model }) {
      if (!classType || !model || !model.id) return;

      DataStore.delete(classType, model.id)
      .then(() => {
        context.dispatch('hotReload');
        setTimeout(() => context.dispatch('coldReload'), 3000);
      })
      .catch((error) => context.commit('setError', error))
      .finally(() => context.dispatch('closeModal'));
    },
    hotReload(context) {
      context.commit('setHotReload', true);
    },
    modalOpen(context, isOpen) {
      context.commit('modalOpen', isOpen);
    },
    openLoginModal(context) {
      context.commit('openLoginModal');
    },
    openModal(context) {
      context.commit('openModal');
    },
    queryModel(context, { classType, pred, opts}) {
      if (!classType) return new PromiseRejectionEvent();

      const newPred = pred ? pred : Predicates.ALL;
      const newOpts = opts ? opts : {
        sort: (model) => model.id(SortDirection.ASCENDING),
      };

      return DataStore.query(classType, newPred, newOpts);
    },
    saveModel(context, model) {
      DataStore.save(model)
        .then(() => {
          context.dispatch('hotReload');
          setTimeout(() => context.dispatch('coldReload'), 3000);
        })
        .catch((error) => context.commit('setError', error))
        .finally(() => context.dispatch('closeModal'));
    },
    setArtworkFingers(context, artwork) {
      context.commit('setArtworkFingers', artwork);
    },
    setContent(context, { component, openModal, props }) {
      if (component) context.commit('setContent', component);
      if (props) context.commit('setProps', props);
      if (openModal) context.commit('openModal');
    },
    setLargeMode(context, isLargeMode) {
      context.commit('setLargeMode', isLargeMode);
    },
    setProps(context, props) {
      context.commit('setProps', props);
    },
    updateModel(context, { classType, model }) {
      if (!classType || !model) return;

      DataStore.query(classType, model.id)
        .then((res) => {
          DataStore.save(
            classType.copyOf(res, (updated) => {
              Object.keys(res).forEach((key) => {
                if (key.indexOf('Date') !== -1 && !isNaN(model[key])) {
                  updated[key] = new Date(model[key]).getTime();
                } else if (key !== 'id' && key.indexOf('_') !== 0) {
                  updated[key] = model[key];
                }
              });
              updated.editedOn = new Date().getTime();
            })
          )
          .then(() => {
            context.dispatch('closeModal');
            context.dispatch('hotReload');
            setTimeout(() => context.dispatch('coldReload'), 3000);
          })
          .catch((error) => context.commit('setError', error))
          .finally(() => context.dispatch('closeModal'));
        })
        .catch((error) => context.commit('setError', error))
        .finally(() => context.dispatch('closeModal'));
    },
  },
  mutations: {
    closeLoginModal(state) {
      state.isLoginModalOpen = false;
    },
    closeModal(state) {
      state.isModalOpen = false;
    },
    modalOpen(state, isOpen) {
      state.isModalActive = isOpen;
    },
    openLoginModal(state) {
      state.isLoginModalOpen = true;
    },
    openModal(state) {
      state.isModalOpen = true;
    },
    setArtworkFingers(state, artwork) {
      state.artworkFingers = artwork;
    },
    setContent(state, content) {
      state.content = content;
    },
    setError(state, error) {
      state.error = error;
    },
    setHotReload(state, hotReload) {
      state.hotReload = hotReload;
    },
    setIsClientLoading(state, isLoading) {
      state.isClientLoading = isLoading;
    },
    setLargeMode(state, isLargeMode) {
      state.isLargeMode = isLargeMode;
    },
    setLastClientQuery(state, clients) {
      state.lastClientQuery = clients;
    },
    setProps(state, props) {
      state.props = props;
    },
  },
  state: {
    artworkFingers: 0,
    content: '',
    error: '',
    hotReload: false,
    isClientLoading: false,
    isLargeMode: false,
    isLoginModalOpen: false,
    isModalActive: false,
    isModalOpen: false,
    lastClientQuery: [],
    props: '',
  },
};

export default Main;

const PICTURE_SETUP = {
    DEFAULT: 0,
    ONE: 1,
    SIDE_BY_SIDE: 2,
    TOP_BOTTOM: 3,
    ONE_LEFT_TWO_RIGHT: 4,
    TWO_LEFT_ONE_RIGHT: 5,
    ONE_TOP_TWO_BOTTOM: 6,
    TWO_TOP_ONE_BOTTOM: 7,
    THREE_SINGLES: 8,
    THREE_TOP_TO_BOTTOM: 9,
    FOUR_BOXES: 10,
};

export default PICTURE_SETUP;